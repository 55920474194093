import * as React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ReactPlayer from 'react-player/youtube'
import IconClose from '../assets/icons/close.png'

export default function DialogVideo (props) {
  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <Dialog onClose={handleClose} open={props.open} fullWidth maxWidth='xl'>
      <div className='d-flex justify-content-end pt-3'>
        <Button onClick={() => props.setOpen(false)}>
          <img src={IconClose} alt='close' />
        </Button>
      </div>
      <div className='p-4'>
        <div style={{ position: 'relative', paddingTop: '56.25%', marginBottom: '-5%' }}>
          <ReactPlayer
            url={props.element.url}
            height='86%'
            width='86%'
            style={{ position: 'absolute', top: 0, left: '7%', right: '7%' }}
          />
        </div>
        <div className='d-flex flex-column ms-4 border-top'>
          <div className='medium pt-2 text-start'>{props.element.title}</div>
          <div className='text-regular text-start'>{props.element.date}</div>
        </div>
      </div>
    </Dialog>
  )
}
