import React, { useEffect, useState } from 'react'
import { Button, Dialog, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { sendTwoFactorCode } from '../services/signup'

const initialValues = {
  n1: '',
  n2: '',
  n3: '',
  n4: '',
  n5: '',
  n6: ''
}

const useStyles = makeStyles(theme => ({
  actions: {
    bottom: 0,
    padding: theme.spacing(4),
    position: 'absolute'
  },
  container: {
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(50),
    maxWidth: theme.spacing(66.5),
    position: 'relative',
    transition: 'all 0.5s ease-in-out',
    width: '100%'
  },
  closeButton: {
    height: theme.spacing(1.75),
    position: 'absolute',
    right: theme.spacing(2.625),
    top: theme.spacing(2.625),
    width: theme.spacing(1.75)
  },
  digitBlock: {
    height: theme.spacing(8),
    left: theme.spacing(15.75),
    overflow: 'hidden',
    position: 'absolute',
    top: theme.spacing(24),
    width: theme.spacing(35),
    '& input': {
      ...theme.typography.h5,
      appearance: 'none',
      backgroundColor: '#F5F5F5',
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderRadius: theme.spacing(12.5),
      height: theme.spacing(8),
      outline: 'none',
      textAlign: 'center',
      width: theme.spacing(5)
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none'
    },
    '& input:not(:placeholder-shown)': {
      border: ({ error }) => (error ? `1px solid ${theme.palette.error.main}` : 'unset')
    }
  },
  errorText: {
    color: 'red',
    height: theme.spacing(2),
    position: 'absolute',
    textAlign: 'center',
    top: theme.spacing(36),
    width: '100%'
  },
  sendAgainBlock: {
    height: theme.spacing(4)
  },
  sendAgainButton: {
    color: '#0097EC'
  },
  submitButton: {
    backgroundColor: 'black !important',
    borderRadius: 30,
    color: 'white !important',
    height: theme.spacing(4),
    width: theme.spacing(11.5)
  },
  textBlock: {
    height: theme.spacing(11),
    left: theme.spacing(7),
    position: 'absolute',
    top: theme.spacing(10),
    width: theme.spacing(52)
  }
}))

export const TwoFactorModal = ({
  isOpen,
  hasError,
  isLoading,
  mobilePhone,
  onClose,
  onSendAgain,
  onSubmit,
  onError
}) => {
  const classes = useStyles()
  const [index, setIndex] = useState()
  const [values, setValues] = useState(initialValues)
  const [sent, setSent] = useState(true)

  const sendCode = () => {
    handleReset()
    sendTwoFactorCode({ mobilePhone })
  }

  const handleClose = () => {
    onClose()
  }

  const handleOnChange = e => {
    const { maxLength, id, value } = e.target
    const fieldIndex = id[id.length - 1]
    let fieldValue = value
    if (value.length >= maxLength) fieldValue = value.slice(0, maxLength)

    if (/^[0-9]\d*(\d+)?$/.test(fieldValue)) {
      setValues({ ...values, [id]: fieldValue })
      setIndex(fieldIndex)
    } else fieldValue = ''
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSent(true)
    onSubmit(
      Object.values(values)
        .map(v => (v.length ? v : 0))
        .join('')
    )
  }

  const handleReset = () => {
    setIndex(0)
    setSent(false)
    setValues(initialValues)
  }

  useEffect(() => {
    if (isOpen) {
      sendCode()
    }
  }, [isOpen]) // eslint-disable-line

  useEffect(() => {
    if (index) {
      const element = document.getElementById(`n${index}`)
      if (index !== 1 && element.nextElementSibling) {
        element.nextElementSibling.focus()
        element.nextElementSibling.select()
      }
    }
  }, [index])

  useEffect(() => {
    if (hasError && sent) {
      handleReset()
    }
  })

  return (
    <Dialog
      classes={{ paper: classes.container }}
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
    >
      <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Grid alignItems='center' className={classes.textBlock} container item justify='center'>
        <Typography variant='h5' weight='bold'>
          Safety First
        </Typography>
        <Typography variant='body2'>
          Please confirm your account by entering the code sent to
        </Typography>
        <Typography variant='body2' weight='bold'>
          {mobilePhone}
        </Typography>
      </Grid>
      <form className={classes.digitBlock} onSubmit={handleSubmit}>
        <Grid alignItems='center' container item justifyContent='space-between'>
          <input
            autoFocus
            // type='number'
            onChange={handleOnChange}
            maxLength='1'
            id='n1'
            value={values.n1}
          />
          <input onChange={handleOnChange} maxLength='1' id='n2' value={values.n2} />
          <input onChange={handleOnChange} maxLength='1' id='n3' value={values.n3} />
          <input onChange={handleOnChange} maxLength='1' id='n4' value={values.n4} />
          <input onChange={handleOnChange} maxLength='1' id='n5' value={values.n5} />
          <input onChange={handleOnChange} maxLength='1' id='n6' value={values.n6} />
        </Grid>
      </form>
      <Typography className={classes.errorText} variant='caption'>
        {hasError ? 'The code didn’t match, please try again.' : ''}
      </Typography>
      <Grid container item justifyContent='space-between' className={classes.actions}>
        <div className={classes.sendAgainBlock}>
          <Typography align='center' variant='caption'>
            Haven’t received the code?
          </Typography>
          <Button className={classes.sendAgainButton} onClick={sendCode}>
            Send again
          </Button>
        </div>
        <Button className={classes.submitButton} onClick={handleSubmit} rounded loading={isLoading}>
          Submit
        </Button>
      </Grid>
    </Dialog>
  )
}
