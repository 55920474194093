import axios from 'axios'
const baseUrl = 'https://platform.accesswages.com/'

const postContact = async ({ emailRecipient, name, email, phone, companyContact, message }) => {
  let response
  try {
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: {
        email_recipient: emailRecipient,
        name,
        email,
        phone,
        company_contact: companyContact,
        message
      }
    }
    response = await axios(baseUrl + 'api/contact-us', request)
    return response
  } catch (error) {
    return { success: false, error }
  }
}

export { postContact }
