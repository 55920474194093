import React from 'react'
import { Button, makeStyles } from '@material-ui/core'

import { useNavigate } from 'react-router-dom'
import SlideImg from '../components/SlideImg'
import TalentImg from '../assets/images/talent.png'
import EnhanceImg from '../assets/images/enhance-job.png'
import NoCostImg from '../assets/images/no-cost.png'
import Blog from '../components/Blog'
import SlideCarousel from '../components/SlideCarousel'
import CarouselImg1 from '../assets/images/carrousel/benefits-1.png'
import CarouselImg2 from '../assets/images/carrousel/benefits-2.png'
import CarouselImg3 from '../assets/images/carrousel/benefits-3.png'
import ImgHeader1 from '../assets/images/benefits-header-1.png'
import ImgHeader2 from '../assets/images/benefits-header-2.png'
import ImgHeader3 from '../assets/images/benefits-header-3.png'
import ImgHeader4 from '../assets/images/benefits-header-4.png'
import FigureHeader from '../assets/images/shapes/benefits-header.png'
import Icon1 from '../assets/images/benefits-icons-1.png'
import Icon2 from '../assets/images/benefits-icons-2.png'
import Icon3 from '../assets/images/benefits-icons-3.png'
import Icon4 from '../assets/images/benefits-icons-4.png'
import Icon5 from '../assets/images/benefits-icons-5.png'
import Icon6 from '../assets/images/benefits-icons-6.png'
import ImgHeaderSm from '../assets/images/benefits-img-header-sm.png'
import Paths from '../helpers/paths'

const useStyles = makeStyles(theme => ({
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    width: '10rem'
  }
}))

export default function Benefits () {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div>
      <div className='d-flex pb-5 header-container row px-1 px-sm-5 px-md-1'>
        <img src={FigureHeader} alt='figure' className='figure-header-benefits' />
        <div className='col-12 col-md-6 px-1 px-lg-5 pt-1 d-none d-md-flex align-items-center'>
          <div className='row px-1 px-lg-5 pt-1'>
            <img src={ImgHeader1} alt='img' className='col-6 benefits-img-header' />
            <img src={ImgHeader2} alt='img' className='col-6 benefits-img-header' />
            <img src={ImgHeader3} alt='img' className='col-6 benefits-img-header' />
            <img src={ImgHeader4} alt='img' className='col-6 benefits-img-header' />
          </div>
        </div>
        <div className='col-12 px-1 pt-1 d-flex d-md-none align-items-center justify-content-center'>
          <img src={ImgHeaderSm} alt='header-img' style={{ width: '100%' }} />
        </div>
        <div className='col-12 col-md-6 d-flex align-items-center p-1 pt-5 p-md-5'>
          <div className='d-flex flex-column p-1 p-lg-5 header-benefits-text-container'>
            <h1 className='web-header d-flex pb-4'>
              Motivated employees are happier, more engaged, and more productive
            </h1>
            <h2 className='large-text pb-3'>
              Multikrd enriches and rewards employees who join your team, contribute fully, and
              stick around.
            </h2>
            <div className='d-flex justify-content-start pt-3'>
              <Button
                variant='outlined'
                className={classes.buttonSecondaryBlack}
                onClick={() => navigate(Paths.CONTACT)}
                style={{ zIndex: '4' }}
              >
                Get a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SlideImg
        button={false}
        title='Attract and retain talent'
        text='Employers who offer smart financial tools are more likely to attract and retain talent. Companies who give employees more savings clout and cash not surprisingly find they earn the loyalty that comes with satisfaction.'
        img={TalentImg}
      />
      <SlideImg
        button={false}
        textPosition='left'
        title='Enhance job performance'
        text='People who are stressed about making ends meet find their worry impacts every part of life—including productivity at work. In fact, employees with financial worries are 5x more likely to produce lower quality work and twice as likely to consider leaving altogether. By increasing your team’s financial resilience, you increase focus and engagement.'
        img={EnhanceImg}
      />
      <SlideImg
        button={false}
        title='No-cost high-impact incentives'
        text='Multikrd allows you to reward, inspire, and empower those you hire at a price you can afford: zero. Strengthen company culture by offering personalized discounts and bonuses. Our platform makes it easy to appreciate your hardworking team.'
        img={NoCostImg}
      />
      <div className='benefits-icons py-5 d-flex flex-column'>
        <div className='web-header d-flex justify-content-center pb-5 '>
          <div className='benefits-icons-title pb-5 pt-5'>
            Be a business where good things happen all the time.
          </div>
        </div>
        <div className='row py-5 px-5 px-lg-1'>
          <div className='col-6 col-md-4 pb-5 mb-5'>
            <img src={Icon1} alt='icon1' className='benefits-icons-icon' />
            <div className='medium pt-3'>Earned wage access</div>
          </div>
          <div className='col-6 col-md-4 pb-5 mb-5'>
            <img src={Icon2} alt='icon2' className='benefits-icons-icon' />
            <div className='medium pt-3'>Discounts</div>
          </div>
          <div className='col-6 col-md-4 pb-5 mb-5'>
            <img src={Icon3} alt='icon3' className='benefits-icons-icon' />
            <div className='medium pt-3'>Cashback</div>
          </div>
          <div className='col-6 col-md-4 pb-5'>
            <img src={Icon4} alt='icon4' className='benefits-icons-icon' />
            <div className='medium pt-3'>Earned tip access</div>
          </div>
          <div className='col-6 col-md-4 pb-5'>
            <img src={Icon5} alt='icon5' className='benefits-icons-icon' />
            <div className='medium pt-3'>MK easy banking access⁽¹⁾</div>
          </div>
          <div className='col-6 col-md-4 pb-5'>
            <img src={Icon6} alt='icon6' className='benefits-icons-icon' />
            <div className='medium p-3'>Special rewards</div>
          </div>
        </div>
      </div>
      <SlideCarousel items={carouselItems} />
      <Blog />
    </div>
  )
}

const carouselItems = [
  {
    title: 'How a white-label platform helped our company.',
    text: 'We have a white-label platform that our employees love and use very often. We worked directly with Multikrd to design the look and feel that aligned with our expectations, and they did not fail to deliver! ',
    autor: '- Charlotte M.,CHRO',
    header: 'USER STORY:',
    img: CarouselImg1,
    design: 2
  },
  {
    title: '“This app gives me a cash float and helps me stay organized…”',
    text: 'I was never able to disburse tips to my staff so easily. I used to go to the bank almost daily, but not anymore. This app gives me a cash float and helps me stay organized with the tips and wages that my staff receives each day. We all love it!',
    autor: '- Nick S., Co-Founder and Owner',
    img: CarouselImg2,
    design: 1
  },
  {
    title: '“The onboarding process was seamless…”',
    text: 'The onboarding process was seamless and we were always provided with support. Multikrd has helped us forget about paper checks and given us the opportunity to use their cards to deposit wages to our personnel in a very simple manner.',
    autor: '- Trina F., HR Director',
    img: CarouselImg3,
    design: 3
  }
]
