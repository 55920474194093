import React, { useRef } from 'react'
import SlideImg from '../components/SlideImg'
import SlideHeader from '../components/SlideHeader'
import ImgHeader from '../assets/images/hiw-1.svg'
import Img2 from '../assets/images/hiw-2.png'
import Img3 from '../assets/images/hiw-3.png'
import LineImg1 from '../assets/images/line-img-1.png'
import LineImg2 from '../assets/images/line-img-2.png'
import LineImg3 from '../assets/images/line-img-3.png'
import LineImg4 from '../assets/images/line-img-4.png'
import CheckIcon from '../assets/icons/check.png'
import ArrowLeft from '../assets/icons/arrow-left.png'
import ArrowRight from '../assets/icons/arrow-right.png'
import { Button } from '@material-ui/core'
import Navbar from '../components/Navbar'

export default function HowItWorks () {
  const scrollRef = useRef(null)

  const executeScroll = scrollOffset => {
    scrollRef.current.scrollTo(scrollOffset, 0)
  }
  return (
    <>
      <div style={{ position: 'relative', display: 'grid' }}>
        <Navbar super />
        <SlideHeader
          textPosition='left'
          img={ImgHeader}
          imgSm={ImgHeader}
          title='Offer unprecedented incentives. We can help. '
          text='Build your workforce while boosting employee confidence and financial resilience. '
          backgroundClass='bg-header-hiw'
        />
      </div>
      <div>
        <SlideImg
          button={false}
          title='Fully customizable with seamless payroll integration'
          text='Our suite of tools allows you to connect with your hardworking teams to reward performance. Using our modular design, employers can fully customize the platform’s look, feel and functionality. Multikrd integrates seamlessly with existing payroll systems, removing administrative burdens from PEO and HR teams.'
          img={Img2}
          background='rgba(193, 216, 47, 0.5)'
        />
        <SlideImg
          button={false}
          textPosition='left'
          title='An extra plus'
          text='Our platform strengthens workplaces while rewarding employees. Our Customer Success team will make sure that Multikrd is aligned to your company goals and customize special promotions that boost productivity among your workforce and increases engagement, making the program a personal experience.'
          img={Img3}
        />
        <div className='d-flex flex-column px-sm-3 px-md-5 px-lg-1'>
          <div className='web-header text-center py-4'>We’re different</div>
          <div className='scroll' ref={scrollRef}>
            <div className='d-flex justify-content-center py-3 pb-5 scroll-div'>
              <div className='card card-white'>
                <div className='list-group-item web-subhead text-center text-md-end card-title-white py-3'>
                  Current market providers
                </div>
                <div className='card-body'>
                  {currentMakeProviders.map((text, index) => {
                    return (
                      <div key={index} className='card-item'>
                        <div
                          className='text-regular text-center text-md-end'
                          style={{ width: '100%' }}
                        >
                          {text}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='card card-blue'>
                <li
                  className='list-group-item web-subhead text-center text-md-start card-title py-3'
                  style={{ color: 'white', background: 'rgba(30, 65, 100, 1)', margin: 0 }}
                >
                  Multikrd
                </li>
                <div className='card-body'>
                  {multikrd.map((text, index) => {
                    return (
                      <div key={index} className='card-item'>
                        <div
                          className='text-regular text-center text-md-start'
                          style={{ color: 'white', width: '100%' }}
                        >
                          {text}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center d-inline d-sm-none'>
            <Button
              onClick={() => {
                executeScroll(-1000)
              }}
            >
              <img src={ArrowLeft} alt='arrow-left' />
            </Button>
            <Button
              onClick={() => {
                executeScroll(1000)
              }}
            >
              <img src={ArrowRight} alt='arrow-right' />
            </Button>
          </div>
        </div>
        <div className='row px-4 pt-5'>
          <img className='col-3' src={LineImg1} alt='lineimg1' />
          <img className='col-3' src={LineImg2} alt='lineimg1' />
          <img className='col-3' src={LineImg3} alt='lineimg1' />
          <img className='col-3' src={LineImg4} alt='lineimg1' />
        </div>
        <div className='d-flex flex-column our-service-container'>
          <div className='web-header text-center py-5 my-5'>Our service</div>
          <div className='row ps-5 pb-5 mb-5'>
            <div className='col-12 col-sm-6 d-flex flex-column ps-1 ps-lg-5'>
              <div className='list-header text-start pb-3'>For employers:</div>
              {ourServiceEmployers.map((text, index) => {
                return (
                  <div key={index} className='text-start list-item py-4'>
                    <img className='icon-list me-4' src={CheckIcon} alt='check' />
                    {text}
                  </div>
                )
              })}
            </div>
            <div className='col-12 col-sm-6 d-flex flex-column ps-1 ps-lg-5'>
              <div className='list-header text-start pb-3'>For employees:</div>
              {ourServiceemployees.map((text, index) => {
                return (
                  <div key={index} className='text-start list-item py-4'>
                    <img className='icon-list me-4' src={CheckIcon} alt='check' />
                    {text}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const currentMakeProviders = [
  'Siloed financial products and services',
  'Limited cashback promotions',
  'Limited promotions for daily essential savings',
  'Low employee utilization',
  'Limited off-platform functionality',
  'Low or no company partnering opportunities'
]
const multikrd = [
  'Holistic, all-in-one solution combines wage and tips access, easy banking services⁽¹⁾ and special offers.',
  'Large promotions program offers 1.2m discounts with 700,000+ retailers ',
  'Multiple daily essential savings',
  'User-friendly tools to source and redeem promotions',
  'Dynamic promotion-finder plugin',
  'Opportunies to partner with PEOs'
]

const ourServiceEmployers = [
  'Rewards',
  'Incentives',
  'Communication tools',
  'Reporting tools',
  'Web and mobile apps (iOS & Android)',
  'PEO customized integrations',
  'Modular design and customization'
]

const ourServiceemployees = [
  'Up to 100% earned wage and tip access',
  '1.2m promotions',
  '700,000+ merchant partnerships',
  'Daily essential savings',
  'MK Easy Banking⁽¹⁾',
  'Promotions finder mobile geo-localization'
]
