import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useNavigate } from 'react-router-dom'

import cardImg from '../../assets/images/regular.png'
import Paths from '../../helpers/paths'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  button: {
    textTransform: 'none'
  },
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    minWidth: '10rem'
  },
  buttonSecondaryWhite: {
    textTransform: 'none',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: 15,
    marginLeft: 15,
    paddingInline: 25
  },
  buttonFooter: {
    textTransform: 'none',
    color: 'white',
    justifyContent: 'start'
  }
}))
export default function FooterSlide () {
  const classes = useStyles()
  const location = useLocation()
  const [visible, setVisible] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === Paths.FAQS || location.pathname === Paths.CONTACT) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }, [location])

  if (visible) {
    return (
      <div className='footer-slide row'>
        <div className='col-12 col-sm-6 col-md d-flex align-items-center justify-content-center'>
          <img alt='Card' src={cardImg} className='footer-img px-5 px-sm-1' />
        </div>
        <div className='col-12 col-sm-6 col-md row d-flex align-items-center justify-content-start'>
          <div className='col-12 col-md-9 col-lg-6 d-flex flex-column '>
            <div className='pb-4 mb-5 mb-sm-1 web-subhead text-center text-sm-start px-5 px-sm-1'>
            {location.pathname === Paths.CUSTOMERS
              ? 'Want MK for all your teammates?'
              : 'See how MK can help your company'
            }
            </div>
            <div className='d-flex justify-content-center justify-content-sm-start mb-5 mb-sm-1'>
              <Button
                variant='outlined'
                className={classes.buttonSecondaryBlack}
                onClick={() => navigate(Paths.CONTACT)}
              >
                {location.pathname === Paths.CUSTOMERS? 'Contact us' : 'Get a demo'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
