import { Button, makeStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  buttonSecondaryWhite: {
    textTransform: 'none',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: 25,
    padding: 10,
    paddingInline: 35
  }
}))

export default function SlideImg (props) {
  const navigation = useNavigate()
  const classes = useStyles()

  return (
    <div className='row pt-4 pt-md-0' style={{ background: props.background }}>
      <div
        className={`col-12 col-sm-6 d-flex align-items-center px-5 pt-1 pt-sm-5 pt-lg-0 ${
          props.textPosition === 'left' ? 'order-2 order-sm-1' : ' order-2'
        } `}
      >
        <div className='d-flex flex-column'>
          <div className='web-subhead d-flex pb-4'>{props.title}</div>
          <div className='slide-img-text d-flex pb-3'>{props.text}</div>
          {props.button === false
            ? (
                ''
              )
            : (
              <div className='d-flex justify-content-start'>
                <Button
                  variant='outlined'
                  className={`${classes.buttonSecondaryWhite}`}
                  onClick={() => {
                    navigation(props.buttonLink)
                  }}
                >
                  Learn more
                </Button>
              </div>
              )}
        </div>
      </div>
      <div
        className={`col-12 col-sm-6 d-flex align-items-center justify-content-center px-2 px-md-5 ${
          props.textPosition === 'left' ? 'order-1 order-sm-2' : 'order-1'
        } `}
      >
        <img
          src={props.img}
          alt='img'
          className='slide-img-img px-1 px-md-5'
          stye={{ maxWidth: '100%' }}
        />
      </div>
    </div>
  )
}
