import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  appBar: {
    boxShadow: 'none'
  },
  tab: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '130%'
  }
}))

export default function SlideTabs (props) {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='primary'
          variant='fullWidth'
          TabIndicatorProps={{ style: { background: '#0097EC' } }}
        >
          <Tab className={classes.tab + ' tab'} label='For Employers' {...a11yProps(0)} />
          <Tab className={classes.tab + ' tab'} label='For Employees' {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {props.components[0]}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {props.components[1]}
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}
