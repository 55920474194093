import React from 'react'
import { IconButton, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate, useLocation } from 'react-router-dom'

import FooterSlide from './FooterSlide'
import Logo from '../../assets/images/logo.png'
import Yt from '../../assets/images/yt.png'
import Fb from '../../assets/images/fb.png'
import In from '../../assets/images/in.png'
import AppStore from '../../assets/images/app-store.png'
import GooglePlay from '../../assets/images/google-play.png'
import Paths from '../../helpers/paths'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  button: {
    textTransform: 'none'
  },
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 15,
    paddingInline: 25
  },
  buttonSecondaryWhite: {
    textTransform: 'none',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: 15,
    marginLeft: 15,
    paddingInline: 25
  },
  buttonFooter: {
    textTransform: 'none',
    color: 'white',
    justifyContent: 'start'
  },
  buttonFooter2: {
    textTransform: 'none',
    color: 'white',
    justifyContent: 'center'
  }
}))

function NavigationButtons() {
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <div className='row' style={{ width: '100%' }}>
      <div className='d-flex col flex-column'>
        <Button
          onClick={() => navigate(Paths.BENEFITS)}
          className={`${classes.buttonFooter} footer-button`}
        >
          For Companies
        </Button>
        <Button
          onClick={() => navigate(Paths.HOW_IT_WORKS)}
          className={`${classes.buttonFooter} footer-button`}
        >
          How it works
        </Button>
        <Button
          onClick={() => navigate(Paths.BENEFITS)}
          className={`${classes.buttonFooter} footer-button`}
        >
          Features
        </Button>
        <Button
          onClick={() => navigate(Paths.EMPLOYEES)}
          className={`${classes.buttonFooter} footer-button d-flex d-sm-none`}
        >
          For Employees
        </Button>
        <Button
          onClick={() => navigate(Paths.ABOUT_US)}
          className={`${classes.buttonFooter} footer-button d-flex d-sm-none`}
        >
          About us
        </Button>
      </div>
      <div className='d-none d-sm-flex col flex-column'>
        <Button
          onClick={() => navigate(Paths.EMPLOYEES)}
          className={`${classes.buttonFooter} footer-button`}
        >
          For Employees
        </Button>
        <Button
          onClick={() => navigate(Paths.ABOUT_US)}
          className={`${classes.buttonFooter} footer-button`}
        >
          About us
        </Button>
        <Button
          onClick={() => navigate(Paths.CONTACT)}
          className={`${classes.buttonFooter} footer-button d-flex d-md-none`}
        >
          Contact us
        </Button>
      </div>
      <div className='d-flex col flex-column'>
        <Button
          onClick={() => navigate(Paths.BLOG)}
          className={`${classes.buttonFooter} footer-button`}
        >
          Resources
        </Button>
        <Button
          onClick={() => navigate(Paths.BLOG)}
          className={`${classes.buttonFooter} footer-button`}
        >
          Blog
        </Button>
        <Button
          onClick={() => navigate(Paths.MEDIA)}
          className={`${classes.buttonFooter} footer-button`}
        >
          Media Center
        </Button>
        <Button
          onClick={() => navigate(Paths.FAQS)}
          className={`${classes.buttonFooter} footer-button`}
        >
          FAQs
        </Button>
        <Button
          onClick={() => navigate(Paths.CONTACT)}
          className={`${classes.buttonFooter} footer-button d-flex d-sm-none`}
        >
          Contact us
        </Button>
      </div>
      <div className='d-none d-md-flex col flex-column'>
        <Button
          onClick={() => navigate(Paths.CONTACT)}
          className={`${classes.buttonFooter} footer-button`}
        >
          Get a demo
        </Button>
        <Button
          onClick={() => navigate(Paths.CONTACT)}
          className={`${classes.buttonFooter} footer-button`}
        >
          Contact us
        </Button>
      </div>
    </div>
  )
}

export default function Footer() {
  const location = useLocation()
  const classes = useStyles()
  if (location.pathname.slice(1) === 'contact-us') return ''
  else {
    return (
      <div className='footer-container'>
        <FooterSlide />
        <div className='footer pt-5 pb-1 pb-md-4'>
          <div className='row pb-3 justify-content-between'>
            <div className='col-1 ps-5 ps-md-1'>
              <img alt='logo' src={Logo} />
            </div>
            <div className='d-none d-md-flex col-md-8'>
              <NavigationButtons />
            </div>
            <div className='col-3 d-flex justify-content-end align-items-start px-5'>
              <IconButton
                onClick={() => {
                  const createA = document.createElement('a')
                  createA.setAttribute(
                    'href',
                    'https://www.youtube.com/channel/UCidMjaOw4jGP7zLsSpEpoVg/featured'
                  )
                  createA.setAttribute('target', '_blank')
                  createA.click()
                }}
                aria-label='Youtube'
              >
                <img src={Yt} alt='Youtube' />
              </IconButton>
              <IconButton
                onClick={() => {
                  const createA = document.createElement('a')
                  createA.setAttribute('href', 'https://www.facebook.com/Multikrd')
                  createA.setAttribute('target', '_blank')
                  createA.click()
                }}
                aria-label='Facebook'
              >
                <img src={Fb} alt='Facebook' />
              </IconButton>
              <IconButton
                onClick={() => {
                  const createA = document.createElement('a')
                  createA.setAttribute('href', 'https://www.linkedin.com/company/multikrd-llc/')
                  createA.setAttribute('target', '_blank')
                  createA.click()
                }}
                aria-label='In'
              >
                <img src={In} alt='In' />
              </IconButton>
            </div>
          </div>
          <div className='d-flex d-md-none px-5'>
            <NavigationButtons />
          </div>
          <div className='d-table d-md-inline-flex flex-row-reverse justify-content-between px-5 pb-3 pt-5 pt-md-4 w-100'>
            <div className='col d-flex justify-content-start justify-content-md-end pb-4 pb-md-1'>
              <IconButton
                onClick={() => {
                  const createA = document.createElement('a')
                  createA.setAttribute('href', 'https://apps.apple.com/us/app/multikrd/id1546988080')
                  createA.setAttribute('target', '_blank')
                  createA.click()
                }}
                aria-label='App Store'
                className='ps-0'
              >
                <img src={AppStore} alt='App Store' />
              </IconButton>
              <IconButton
                onClick={() => {
                  const createA = document.createElement('a')
                  createA.setAttribute(
                    'href',
                    'https://play.google.com/store/apps/details?id=com.multikrd.android'
                  )
                  createA.setAttribute('target', '_blank')
                  createA.click()
                }}
                aria-label='Google Play'
              >
                <img src={GooglePlay} alt='Google Play' />
              </IconButton>
            </div>
            <div
              className='regular-text d-flex  align-items-center justify-content-start justify-content-md-center'
              style={{ color: 'white' }}
            >
              <div className='pe-3'>
                <Link
                  href='https://platform.accesswages.com/legal-agreements'
                  target='_blank'
                  color='inherit'
                  className={`${classes.buttonFooter2} footer-link`} rel='noreferrer'
                >
                  Legal Agreements
                </Link>
              </div>
              |
              <div className='ps-3'>
                <Link
                  href='https://platform.accesswages.com/legal-agreements?section=Privacy%20Policy'
                  target='_blank'
                  color='inherit'
                  className={`${classes.buttonFooter2} footer-link`} rel='noreferrer'
                >
                  Privacy policy
                </Link>
              </div>
            </div>
            <div className='col d-flex justify-content-start align-items-center footer-text-1'>
              ©{new Date().getFullYear()} Multikrd LLC. All rights reserved
            </div>
          </div>
          <div className='col d-flex justify-content-start align-items-center footer-text-1 px-5 pb-3'>
            Multikrd LLC is a financial technology company and not a FDIC-insured
            bank. Checking accounts and the Multikrd Visa Debit Card are provided
            by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking
            account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s
            deposit insurance coverage only protects against the failure of a FDIC-insured
            depository institution.
          </div>
        </div>
      </div>
    )
  }
}
