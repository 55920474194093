import Carousel from 'react-material-ui-carousel'
import FigureCarousel1 from '../assets/images/shapes/carousel-home.png'
import FigureCarousel2 from '../assets/images/shapes/benefits-carousel.png'
import FigureCarousel3 from '../assets/images/shapes/employees-carousel.png'

export default function SlideCarousel (props) {
  const design = {
    1: {
      background: '#1E4164',
      textColor: 'white',
      figure: FigureCarousel1
    },
    2: {
      background: '#FDBA63',
      textColor: 'black',
      figure: FigureCarousel2
    },
    3: {
      background: '#73C16C',
      textColor: 'white',
      textColorSecondary: 'black',
      figure: FigureCarousel3
    }
  }
  return (
    <Carousel className='w-100 carrousel pb-5 pb-lg-0'>
      {props.items.map((item, i) => (
        <Item
          key={i}
          item={item}
          figure={design[item.design].figure}
          textColor={design[item.design].textColor}
          background={design[item.design].background}
          textColor1={item.design === 3 ? 'black' : design[item.design].textColor}
        />
      ))}
    </Carousel>
  )
}

function Item (props) {
  return (
    <div className='slide-carousel row ps-4 ps-md-5' style={{ background: props.background }}>
      <div className='col-12 col-md-6 d-flex flex-column align-items-center px-0 px-md-5 justify-content-center '>
        {props.item.header === undefined
          ? (
              ''
            )
          : (
            <div className='slide-carrousel-header pb-4'>{props.item.header}</div>
            )}
        <div className='row'>
          <div
            className='col-6 col-md-12 slide-carousel-title text-start'
            style={{ color: props.textColor }}
          >
            {props.item.title}
          </div>
          <div
            className='col-6 d-flex d-md-none justify-content-end align-items-end px-5'
            style={{ position: 'relative' }}
          >
            <img className='carrousel-img' alt='carouselImg' src={props.item.img} />
            {props.figure !== undefined
              ? (
                <img src={props.figure} alt='figure' className='carousel-figure' />
                )
              : (
                  ''
                )}
          </div>
        </div>
        <div className='carrousel-item'>
          <div className='slide-carousel-text py-4' style={{ color: props.textColor1 }}>
            {props.item.text}
          </div>
          <div className='slide-carousel-text pb-5 pb-md-2' style={{ color: props.textColor1 }}>
            {props.item.autor}
          </div>
        </div>
      </div>
      <div className='col-6 d-none d-md-flex justify-content-end align-items-end pe-5'>
        <img className='carrousel-img' alt='carouselImg' src={props.item.img} />
        {props.figure !== undefined
          ? (
            <img src={props.figure} alt='figure' className='carousel-figure' />
            )
          : (
              ''
            )}
      </div>
    </div>
  )
}
