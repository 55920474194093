import React from 'react'
import ImgForm from '../assets/images/contact.png'
import Icon1 from '../assets/icons/contact-1.png'
import Icon2 from '../assets/icons/contact-2.png'
import Icon3 from '../assets/icons/contact-3.png'
import Icon4 from '../assets/icons/contact-4.png'
import ContactForm from '../components/contactForm'

export default function Contact () {
  return (
    <div className='px-md-3 px-lg-5 pt-5'>
      <div className='row p-5'>
        <div className='web-header'>Interested in Multikrd?</div>
        <div className='large-text pt-5 pb-3'>Tell us a little more and we’ll be in touch!</div>
      </div>
      <div className='row pt-0 pt-5-md pb-5'>
        <div className='col-6 d-none d-md-inline'>
          <img src={ImgForm} alt='imgForm' />
        </div>
        <div className='col-12 col-md-6 px-5 px-md-0'>
          <ContactForm emailRecipient='sales@multikrd.com' />
        </div>
      </div>
      <div className='row pt-5 pb-5 mb-5'>
        {contactInfo.map((e, index) => {
          return (
            <div key={index} className='col-12 col-sm-6 col-md-3 p-5 p-md-3 p-lg-5'>
              <div className='d-flex justify-content-start pb-4'>
                <img src={e.icon} alt={e.title} />
              </div>
              <div className='large-text text-start bold'>{e.title}</div>
              <div className='text-regular text-start'>{e.text}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contactInfo = [
  {
    title: 'Address',
    text: (
      <>
        10800 Gosling Rd, Box 131834 <br /> Spring TX, 77381-9998
      </>
    ),
    icon: Icon1
  },
  {
    title: 'Email',
    text: 'sales@multikrd.com',
    icon: Icon2
  },
  {
    title: 'Phone',
    text: '1-866-995-2736',
    icon: Icon3
  },
  {
    title: 'Hours',
    text: <>Mon - Fri: 9am - 5pm</>,
    icon: Icon4
  }
]
