import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import Paths from '../helpers/paths'

export default function MetaTags (props) {
  const location = useLocation()

  switch (location.pathname) {
    case Paths.BENEFITS:
      return (
        <Helmet>
          <title>Financial Wellness Benefits for Employees | Multikrd LLC</title>
          <meta
            name='description'
            content='Enhance your employee&#39;s financial wellness with our comprehensive benefits program. Multikrd offers a range of financial wellness employee benefits to support your staff. Contact us today!'
          />
        </Helmet>
      )
    case Paths.EMPLOYEES:
      return (
        <Helmet>
          <title>Employee Financial Wellness, Engagement &amp; Experience Programs</title>
          <meta
            name='description'
            content='Boost employee satisfaction and financial well-being with Multikrd&#39;s Employee Financial Wellness Program, cashback, discounts, on-demand tips and wages, and a secured credit card for employees. Enhance employee engagement and recognition with our Employee Engagement and Experience Program. Contact us today!'
          />
        </Helmet>
      )
    case Paths.HOW_IT_WORKS:
      return (
        <Helmet>
          <title>
            HR Perks Program | Payroll HR Solutions | Pay On Demand Service - Multikrd LLC
          </title>
          <meta
            name='description'
            content='Revolutionize your payroll with Multikrd&#39;s Pay On Demand Service, Payroll Direct Deposit, and Payroll Paycard options. Streamline your payroll system with our PEO, HR and Payroll seamless integrations. Visit our website today!'
          />
        </Helmet>
      )
    default:
      return (
        <Helmet>
          <title>
            Employee Recognition, Success Talent Attraction & Retention Program | Multikrd LLC
          </title>
          <meta
            name='description'
            content='Boost employee morale with our Staff Recognition Program & Employee Success Program. Same day pay & paycheck advance options are available for talent attraction and retention. Visit Now!'
          />
        </Helmet>
      )
  }
}
