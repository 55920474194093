import { MULTIKRD_DOMAINS } from './constants'

export const parseSnakeCase = object => {
  if (typeof object !== 'object' || object === null) {
    throw new Error('Input must be a non-null object.')
  }

  const snakeObject = {}

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const snakeKey = key.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`)
      snakeObject[snakeKey] = object[key]
    }
  }

  return snakeObject
}

export const checkB2CSubdomain = () => {
  const subdomain = window.location.hostname.split('.')[0]
  return !MULTIKRD_DOMAINS.includes(subdomain)
}
