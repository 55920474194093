import { Button, makeStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import Paths from '../helpers/paths'

const useStyles = makeStyles(theme => ({
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    minWidth: '10rem'
  }
}))

export default function SlideHeader (props) {
  const navigate = useNavigate()
  const classes = useStyles()

  // if (props.textPosition === 'left') {
  return (
    <div className={'d-flex row pt-1 pb-5 pb-md-1 header-container ' + props.backgroundClass}>
      {props.figure !== undefined
        ? (
          <img src={props.figure} alt='figure' className={props.figureClass} />
          )
        : (
            ''
          )}
      <div
        className={`col-12 px-0 px-sm-5 pb-4 d-inline d-md-none ${
          props.textPosition === 'left' ? 'order-1 order-md-2' : 'order-1'
        } `}
      >
        <img src={props.imgSm} alt='img' className='img-header' />
      </div>
      <div
        className={`col-12 col-md-6 d-flex align-items-center p-1 p-md-5 d-flex ${
          props.textPosition === 'left' ? 'order-1' : 'order-2 order-md-2'
        } `}
      >
        <div className='d-flex flex-column'>
          <div
            className={`header-text-container ${props.textPosition === 'left' ? '' : 'ps-md-0'} `}
          >
            <h1 className='web-header d-flex pb-4'>{props.title}</h1>
            <div className='largue-text pb-3'>{props.text}</div>
            <div className='d-flex justify-content-start'>
              <Button
                variant='outlined'
                className={classes.buttonSecondaryBlack}
                onClick={() => navigate(props.buttonPath ?? Paths.CONTACT)}
              >
                {props.buttonText ?? 'Get a demo'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`col-6 d-none d-md-inline ${
          props.textPosition === 'left' ? 'order-1 order-md-2' : 'order-1 p-5'
        } `}
        style={{ position: 'relative' }}
      >
        <img src={props.img} alt='img' className='img-header' style={{ maxWidth: '80%' }} />
      </div>
    </div>
  )
  // (
  //   <div className={'d-flex row pt-0 pb-1 header-container' + props.backgroundClass}>
  //     {props.figure !== undefined ?
  //       <img src={props.figure} alt='figure' className={props.figureClass} />
  //       : ''}
  //     <div className={`col-12 px-0 px-sm-5 pb-4 d-inline d-md-none ${props.textPosition === 'left' ? 'order-1 order-md-2' : 'order-1'} `}>
  //       <img src={props.imgSm} alt='img' className='img-header' />
  //     </div>
  //     <div className={`col-12 col-md-6 d-flex align-items-center p-1 p-md-5 pb-5 pb-md-0 d-flex ${props.textPosition === 'left' ? 'order-1' : 'order-2 order-md-2'} `}>
  //       <div className='d-flex flex-column'>
  //         <div className={`header-text-container ${props.textPosition === 'left' ? '':'ps-md-0'} `}>
  //           <div className='web-header d-flex pb-4'>{props.title}</div>
  //           <div className='largue-text pb-3'>{props.text}</div>
  //           <div className='d-flex justify-content-start'>
  //             <Button variant='outlined' className={classes.buttonSecondaryBlack} onClick={() => navigate(Paths.CONTACT)}>
  //               Get a demo
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className={`col-6 d-none d-md-inline ${props.textPosition === 'left' ? 'order-1 order-md-2' : 'order-1 p-5'} `} style={{ position: 'relative' }}>
  //       <img src={props.img} alt='img' className='img-header' />
  //     </div>
  //   </div>
  // )
  // } else {
  //   return (
  //     <div className='d-flex pt-4 pb-5 header-container'>
  //       {props.figure !== undefined ?
  //         <img src={props.figure} alt='figure' className={props.figureClass} />
  //         : ''}
  //       <div className='col-6'>
  //         <img src={props.img} alt='img' />
  //       </div>
  //       <div className='col-6 d-flex align-items-center p-5'>
  //         <div className='d-flex flex-column p-5'>
  //           <div className='web-header d-flex pb-4'>{props.title}</div>
  //           <div className='large-text pb-3'>{props.text}</div>
  //           <div className='d-flex justify-content-start'>
  //             <Button variant='outlined' className={classes.buttonSecondaryBlack} onClick={()=>navigate(Paths.CONTACT)}>
  //               Get a demo
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
}
