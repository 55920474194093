import React from 'react'
import { useNavigate } from 'react-router-dom'

import SlideImg from '../components/SlideImg'
import Advance from '../assets/images/advance.png'
import Card from '../assets/images/promo-card.png'
import Boosting from '../assets/images/boosting.png'
import Acordion from '../components/Acordion'
import ImgHeaderSm from '../assets/images/home-header-sm.png'
import ImgHeaderXs from '../assets/images/home-header-xs.png'
import ImgWin from '../assets/images/win-win.svg'
import CarouselImg1 from '../assets/images/carrousel/home-1.png'
import CarouselImg2 from '../assets/images/carrousel/home-2.png'
import CarouselImg3 from '../assets/images/carrousel/home-3.png'
import Blog from '../components/Blog'
import SlideCarousel from '../components/SlideCarousel'
import SlideTabs from '../components/SlideTabs'
import { Button, makeStyles } from '@material-ui/core'
import AcordionImg from '../assets/images/home-acordions.png'
import Paths from '../helpers/paths'

const useStyles = makeStyles(theme => ({
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    width: '10rem'
  }
}))

export default function Home () {
  const classes = useStyles()
  const navigation = useNavigate()

  return (
    <div>
      <div className='pt-4 home-header d-none d-md-flex'>
        <div className='col-6' />
        <div className='col-6 d-flex align-items-start align-items-md-center p-5'>
          <div className='d-flex flex-column p-1 p-xl-5'>
            <h1 className='web-header d-flex pb-4' style={{ color: 'white' }}>
              It’s a win-win deal
            </h1>
            <h2 className='large-text pb-3'>
              Multikrd is a zero-cost solution designed to boost your employees’ financial health.
              Effectively recognize, reward and retain all the great people you hire.
            </h2>
            <div className='d-flex justify-content-start'>
              <Button
                variant='outlined'
                className={classes.buttonSecondaryBlack}
                onClick={() => navigation(Paths.CONTACT)}
              >
                Get a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-4 d-md-none d-flex  mb-5 mb-sm-1'>
        <div className='d-flex flex-column '>
          <img src={ImgHeaderSm} alt='img-header' className='px-5 d-none d-sm-inline' />
          <img src={ImgHeaderXs} alt='img-header' className='d-inline d-sm-none' width='100%' />
          <div className='px-5 d-flex flex-column'>
            <h1 className='web-header d-flex py-4' style={{ color: 'black' }}>
              It’s a win-win deal
            </h1>
            <h2 className='large-text pb-3'>
              Multikrd is a zero-cost solution designed to boost your employees’ financial health.
              Effectively recognize, reward and retain all the great people you hire.
            </h2>
            <div className='d-flex justify-content-start'>
              <Button
                variant='outlined'
                className={classes.buttonSecondaryBlack}
                onClick={() => navigation(Paths.CONTACT)}
              >
                Get a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SlideImg
        title='Unlike any other financial tool'
        text='Multikrd is the ONLY platform with earned wage access, cashback savings and customized discounts that also provides access to a bank account with direct deposit and a Debit Card.⁽¹⁾
With Multikrd, employees get money when they need it, save when they shop, and build financial security.'
        img={Advance}
        buttonLink={Paths.EMPLOYEES}
      />
      <SlideImg
        textPosition='left'
        title='Personalized promotions and savings'
        text='Aligned with America’s largest promotions network, we offer a wide range of discounts, deals, and cashback at over a million retail and travel partners. Our cardholders enjoy perks everywhere, with rewards that suit their preferences.  '
        img={Card}
        buttonLink={Paths.EMPLOYEES}
      />
      <SlideImg
        title='Boosting employee financial wellness is better for your bottom line'
        text='By offering a diverse range of low-risk financial tools, we help employees take big steps to improve their financial lives. It’s no surprise that talented people want to join, and stay at, companies who care.'
        img={Boosting}
        buttonLink={Paths.BENEFITS}
      />
      <div className='d-none d-md-inline'>
        <div className='row px-3'>
          <div className='col-6 px-5'>
            <div className='title pb-3'>A win-win platform</div>
            <div className='text pb-5'>Explore company-wide benefits at a glance</div>
            <div className='web-subhead pb-5'>For Employers</div>
            <div className='pb-5 mb-5'>
              <Acordion content={AccordionContentForEmployers} />
            </div>
            <div className='ps-1 ps-lg-5 ms-5 d-flex flex-column'>
              <div className='d-flex justify-content-start'>
                <img src={AcordionImg} alt='img-3' className='pt-0 mt-0' style={{ width: '70%' }} />
              </div>
              <div className='web-subhead pt-5 win-win-sub'>
                We are your ally in best practices for{' '}
                <div style={{ color: 'rgba(0, 151, 236, 1)' }}>employee happiness</div> & retention
              </div>
            </div>
            <div />
          </div>
          <div className='col-6 px-5 pb-5'>
            <img src={ImgWin} alt='percentage' className='p-3 win-win-img' />
            <div className='web-subhead py-5'>For Employees</div>
            <Acordion content={AccordionContentForEmployees} />
          </div>
        </div>
        <div className='row px-3 pb-5'>
          <div className='col-6 px-5' />
          <div className='px-5 pt-5 pb-5 employees-subtext'>
            {' '}
            (1) Multikrd LLC is a financial technology company and not a FDIC-insured
            bank. Checking accounts and the Multikrd Visa Debit Card are provided
            by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking
            account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s
            deposit insurance coverage only protects against the failure of a FDIC-insured
            depository institution.
            <br />
            <br />
            (2) "Ceridian Study: Vast Majority of Working Americans Want to be Paid On-Demand".
            Dayforce. September 13, 2021
          </div>
        </div>
      </div>
      <div className='d-inline d-md-none'>
        <div className='px-3 pt-5'>
          <img src={ImgWin} alt='percentage' className='p-3 win-win-img pb-5' />
          <div className='title pb-3'>A win-win platform</div>
          <div className='text pb-5'>Explore company-wide benefits at a glance</div>
          <SlideTabs
            components={[
              <Acordion key='acordion1' content={AccordionContentForEmployers} />,
              <Acordion key='acordion2' content={AccordionContentForEmployees} />
            ]}
          />
          <div className='row py-5 px-5 my-5'>
            <img src={AcordionImg} alt='img-3' className='col pe-2' />
            <div className='col d-flex align-items-center ps-2'>
              <div className='web-subhead win-win-sub'>
                We are your ally in best practices for{' '}
                <div style={{ color: 'rgba(0, 151, 236, 1)', width: '33%' }}>
                  employee happiness
                </div>{' '}
                & retention
              </div>
            </div>
          </div>
          <div className='pb-5 employees-subtext'>
            {' '}
            (1) Multikrd LLC is a financial technology company and not a FDIC-insured
            bank. Checking accounts and the Multikrd Visa Debit Card are provided
            by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking
            account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s
            deposit insurance coverage only protects against the failure of a FDIC-insured
            depository institution.
            <br />
            <br />
            (2) "Ceridian Study: Vast Majority of Working Americans Want to be Paid On-Demand".
            Dayforce. September 13, 2021
          </div>
        </div>
      </div>
      <SlideCarousel items={carouselItems} />
      <Blog />
    </div>
  )
}

const AccordionContentForEmployers = [
  {
    title: 'Attract talent',
    text: 'According to a recent Harris survey over 80% of those polled said they would take a job with an employer that offers wages on demand over an employer that does not.⁽²⁾'
  },
  {
    title: 'Keep talent',
    text: 'Employees in that same poll overwhelmingly 79% said earned wage access would make them feel more valued as an employee.⁽²⁾'
  },
  {
    title: 'Lower absenteeism',
    text: 'When employees have control over their pay they are less stressed, more motivated, and more engaged.'
  },
  {
    title: 'Reach HR goals',
    text: 'In-platfrom spot bonuses and gifts are a low-cost way to say “thank you” for a job well done, or to mark a special occasion like a birthday.'
  },
  {
    title: 'Lessen the burden on your HR department',
    text: 'Our platform is easy to set up and easy to run at ZERO COST to you. Remove expenses and administrative burden all at once.'
  }
]

const AccordionContentForEmployees = [
  {
    title: 'Financial control',
    text: 'With access to their paycheck or tips without having to wait for payday, employees have more control over their finances overall.'
  },
  {
    title: 'Peace of mind',
    text: 'As reported in a 2021 Wagestream report, 77% of EWA users felt less stress and 88% reduced reliance on high-cost payday loans. '
  },
  {
    title: 'Safety net',
    text: 'Imagine the relief of being able to cover a large bill or sudden expense without having to pay interest of any kind.'
  },
  {
    title: 'Easy access to banking services',
    text: "Nearly half of work- class americans are underbanked because they don't meet the requirements to open a bank account. The MK Card and account are accessible to most Americans, with simple application requirements and a great user experience to manage their funds with ease."
  },
  {
    title: 'Cashback, again and again',
    text: 'Our MK platform offers cashback at popular retailers, allowing employees to accrue extra cash for a rainy day or special occasion.'
  },
  {
    title: 'Customized coupons and savings',
    text: 'We offer more than a million perks and discounts on groceries, restaurants, clothing, and more.  '
  },
  {
    title: 'Travel discounts galore',
    text: 'Cars, vacations, and flights are just the beginning.  '
  }
]

const carouselItems = [
  {
    title: '“Our attrition rate decreased by 25% without any additional overhead.”',
    text: 'Multikrd allowed our company to increase employee retention. Our attrition rate decreased by 25% without any additional overhead. Our employees are happier than ever enjoying access to their wages without having to wait until payday.',
    autor: '-Catie F., HR Manager',
    img: CarouselImg1,
    design: 1
  },
  {
    title: '“Candidates have never been so curious and appreciative for a solution like this”',
    text: 'Our employee perks program was enhanced and it has helped us attract new talent. Candidates have never been so curious and appreciative for a solution like this.',
    autor: '- Alexander T., SR. HR Specialist',
    img: CarouselImg2,
    design: 3
  },
  {
    title: '“I love that I can request a card for each family member”',
    text: 'My wife and older son have their own cards that are linked to my account. It is very easy to lock and unlock cards and to transfer money between the family. ',
    autor: '- Ben L., Certified Medical Assistant',
    img: CarouselImg3,
    design: 2
  }
]
