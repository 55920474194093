import React from 'react'
import SlideImg from '../components/SlideImg'
import SlideHeader from '../components/SlideHeader'
import ImgHeader from '../assets/images/about-header.png'
import Img1 from '../assets/images/about-1.png'
import Img2 from '../assets/images/about-2.png'
import Img3 from '../assets/images/about-3.png'
import GabrielSanchez from '../assets/images/leadership/gabriel-sanchez.png'
import MarkBakker from '../assets/images/leadership/mark-bakker.png'
import RobertoRaffo from '../assets/images/leadership/roberto-raffo.png'
import AshleighErwin from '../assets/images/leadership/ashleigh-erwin.png'
import FigureHeader from '../assets/images/shapes/about-header.png'

export default function About () {
  return (
    <div>
      <SlideHeader
        textPosition='right'
        img={ImgHeader}
        imgSm={ImgHeader}
        title='Multikrd® runs on the power of appreciation.'
        text='Employee recognition and reward is central to our platform.'
        figure={FigureHeader}
        figureClass='figure-header-about'
      />
      <SlideImg
        button={false}
        textPosition='left'
        title='Our Company'
        text='Multikrd enables companies to attract, reward, and retain an engaged workforce while improving the overall financial well-being of employees. Our digital platform integrates a variety of financial tools with the largest promotions network in America. We provide quick and easy employee access to earned wages and earned tips. Our users discover the best online discounts, cashbacks and savings, as well as our MK easy banking access⁽¹⁾. Our service comes at zero cost to employers and is simple to navigate, providing an exceptional user experience.  We are a solutions-oriented win-win enterprise.'
        img={Img1}
      />
      <SlideImg
        button={false}
        title='Mission & vision'
        text='Our mission is to strengthen workforces by improving employees’ ability to reach financial freedom and resilience. By implementing innovative thinking, we plan to become the most effective employee engagement and retention solution in the nation. Our clients will be the most competitive in their fields, and their employees the most valued.'
        img={Img2}
      />
      <SlideImg
        button={false}
        textPosition='left'
        title='Our Focus'
        text='We are proud to deliver a smart, sophisticated platform that gives companies an advantage in employee productivity and retention. We strive to give both employers and employees the edge they need to succeed.'
        img={Img3}
      />
      <div className='p-5 about-team-work mt-5'>
        <div className='web-subhead text-center p-5'>Teamwork</div>
        <div className='d-flex justify-content-center pb-5'>
          <div className='text-regular' style={{ width: '50%' }}>
            We are always improving our platform. If you’d like to join a multidisciplinary team
            dedicated to providing a seamless and extraordinary end-user experience, read below.
          </div>
        </div>
      </div>
      <div className='p-5' style={{ background: 'rgba(0, 99, 190, 1)', color: 'white' }}>
        <div className='web-subhead text-center p-5'>Leadership</div>
        <div className='row pb-5'>
          {leadershipContent.map((e, index) => {
            return (
              <div
                key={e.name + index}
                className='col-12 col-sm-6 col-md-4 p-3 p-lg-5'
                style={{ color: 'white' }}
              >
                <div className='d-flex justify-content-center justify-content-sm-start pb-1 pb-md-5 mt-5 mt-md-5'>
                  <img src={e.img} alt={e.name} className='leadership-img' />
                </div>
                <div className='semibold  text-center text-sm-start pt-3'>{e.name}</div>
                <div className='text-regular text-center text-sm-start pb-3'>{e.position}</div>
                <div className='leadership-text text-center text-sm-start pe-0 pe-md-5'>
                  {e.descrition}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='d-flex row py-5'>
        <div className='col-12 col-md-6 d-flex align-items-center justify-content-center p-5'>
          <div className='d-flex flex-column px-5' style={{ width: '90%' }}>
            <div className='web-subhead d-flex pb-4'>Join us</div>
            <div className='text-regular text-start pb-2'>
              We’re looking for people to join our team. Send us your resume.
            </div>
            <div className='d-table d-sm-flex justify-content-start pt-5 text-regular'>
              {/* <Button variant='outlined' className={`${classes.buttonSecondaryBlack}`}>
                Send resume
              </Button> */}
              Send resume to:<b className='ps-3'> joinus@multikrd.com</b>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 d-flex justify-content-center align-items-center p-5'>
          <div className='d-flex flex-column w-100'>
            {links.map(e => {
              return (
                <div key={e.title} className='about-links d-flex justify-content-between'>
                  <div className='about-links-text' style={{ color: '#0097EC' }}>
                    {e.title}
                  </div>
                  {/* <img src={Arrow} alt='arrow' /> */}
                  <div />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const leadershipContent = [
  {
    name: 'Gabe Sanchez',
    position: 'Chief Executive Officer, Chief Technology Officer',
    descrition:
      'CEO and CTO, Gabe Sanchez, has experience as an information technology leader in global financial markets. He has held Senior Executive roles in large financial corporations and specializes in Digital Transformation, Regulatory Environments, IT Strategic Planning, Application Development, and IT Infrastructure.',
    img: GabrielSanchez
  },
  {
    name: 'Mark Bakker',
    position: 'Chief Financial Officer',
    descrition:
      'At Deloitte and boutique Corporate Finance Advisory firms, Mark has gained expertise in financial modeling, business valuation and M&A, serving small-sized to publicly listed companies in the US, Netherlands and Mexico. At Multikrd, Mark oversees our financial projection models, pricing, data analysis, among other finance tasks.',
    img: MarkBakker
  },
  {
    name: 'Roberto Raffo',
    position: 'Operations Director',
    descrition:
      'Roberto Raffo, Operations Director, is a highly qualified financial institutions professional with more than 20 years of experience in Finance, Banking, Financial Regulatory Reporting, Banking Operations, Bank Secrecy Act and Anti-Money Laundering (BSA/AML).',
    img: RobertoRaffo
  },
  {
    name: 'Ashleigh Erwin',
    position: 'Executive Account Director',
    descrition:
      'Ashleigh Erwin, Executive Account Director, has extensive expertise in public relations, branding, marketing, copywriting and content development. On the Multikrd executive team, Ashleigh facilitates between marketing and sales departments, developing strategic plans tailored for individual client accounts. She nurtures consistent account service and utilization, leading to strong client success.',
    img: AshleighErwin
  }
]

const links = [
  {
    title: 'Operations',
    link: '/'
  },
  {
    title: 'Marketing',
    link: '/'
  },
  {
    title: 'Engineering',
    link: ''
  },
  {
    title: 'Sales & partnerships',
    link: ''
  }
]
