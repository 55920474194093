import { Box, Collapse, List, ListItem, SwipeableDrawer } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import MenuListComponent from './MenuList'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import IconMenu from '../assets/icons/menu.png'
import IconClose from '../assets/icons/close.png'
import Logo from '../assets/images/logo.png'
import IconArrowRight from '../assets/icons/rightarrow.png'
import IconArrowDown from '../assets/icons/downarrow.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { checkB2CSubdomain } from '../helpers/utils'
import { B2C_FEATURE_ACTIVE } from '../helpers/constants'
import Paths from '../helpers/paths'

const currentDomain = window.location.hostname.split('.')[0]
const MULTIKRD_B2C_SUBDOMAIN = currentDomain === 'multikrd' ? 'consumer' : 'consumer-test'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  button: {
    textTransform: 'none',
    fontFamily: 'Archia'
  },
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 5,
    paddingTop: 10,
    paddingBottom: 10,
    width: '8rem',
    marginRight: 5
  },
  buttonSecondaryWhite: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    color: 'black',
    borderRadius: 30,
    paddingTop: 10,
    paddingBottom: 10,
    width: '8rem'
  },
  buttonFooter: {
    textTransform: 'none',
    color: 'white',
    justifyContent: 'start'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  navbarMobile: {
    minWidth: 50,
    maxWidth: 500
    // width:'100%'
  }
}))

export default function Navbar (props) {
  const navigate = useNavigate()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [local, setLocal] = useState('')
  const location = useLocation()
  const [openCollapse, setOpenCollapse] = useState(false)
  const [openCollapse2, setOpenCollapse2] = useState(false)

  const isB2C = checkB2CSubdomain()

  useEffect(() => {
    if (
      location.pathname.slice(1) === 'how-it-works' ||
      location.pathname.slice(1) === 'benefits'
    ) {
      setLocal('For Companies')
    } else if (
      location.pathname.slice(1) === 'media' ||
      location.pathname.slice(1) === 'FAQs' ||
      location.pathname.slice(1) === 'blog'
    ) {
      setLocal('Resources')
    } else {
      setLocal(location.pathname.slice(1))
    }
  }, [location.pathname])

  const onClose = () => {
    setOpenCollapse(false)
    setOpenCollapse2(false)
    setOpen(false)
  }
  const MobileMenu = () => (
    <Box
      role='presentation'
      onKeyDown={() => onClose()}
      className={`p-3 d-inline d-md-none navbar-mobile ${classes.navbarMobile}`}
    >
      <List>
        <ListItem className='py-4 d-flex justify-content-end'>
          <Button onClick={() => onClose()} style={{ border: '0px', padding: '1rem' }}>
            <img src={IconClose} alt='close' />
          </Button>
        </ListItem>
        <ListItem
          button
          className='py-3 menu-item menu-collapse-button'
          onClick={() => setOpenCollapse(!openCollapse)}
        >
          <div className='menu-text'>For Companies</div>
          {openCollapse
            ? (
              <img src={IconArrowDown} alt='arrow-down' />
              )
            : (
              <img src={IconArrowRight} alt='arrow-right' />
              )}
        </ListItem>
        <Collapse in={openCollapse} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              button
              className='ps-3'
              onClick={() => {
                setLocal('For companies')
                navigate(Paths.BENEFITS)
                onClose()
              }}
            >
              <div className='menu-sub-text ps-3'>Benefits</div>
            </ListItem>
            <ListItem
              button
              className='ps-3 py-3'
              onClick={() => {
                setLocal('For companies')
                navigate(Paths.HOW_IT_WORKS)
                onClose()
              }}
            >
              <div className='menu-sub-text ps-3'>How it works</div>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          className='py-3 menu-item'
          onClick={() => {
            setLocal('employees')
            navigate(Paths.EMPLOYEES)
            onClose()
          }}
        >
          <div className='menu-text'>For Employees</div>
        </ListItem>
        {B2C_FEATURE_ACTIVE && (
          <ListItem
            button
            className='py-3 menu-item'
            onClick={() => {
              setLocal('customers')
              if (isB2C) {
                navigate(Paths.CUSTOMERS)
              } else {
                const consumerURL = window.location.origin.replace(
                  window.location.hostname,
                  `${MULTIKRD_B2C_SUBDOMAIN}.${window.location.hostname}`
                )
                if (!window.location.origin.includes(MULTIKRD_B2C_SUBDOMAIN)) {
                  window.location.replace(consumerURL)
                }
              }
              onClose()
            }}
          >
            <div className='menu-text'>For direct users</div>
          </ListItem>
        )}
        <ListItem
          button
          className='py-3 menu-item'
          onClick={() => {
            setLocal('about-us')
            navigate(Paths.ABOUT_US)
            onClose()
          }}
        >
          <div className='menu-text'>About us</div>
        </ListItem>
        <ListItem
          button
          className='py-3 menu-item menu-collapse-button'
          onClick={() => setOpenCollapse2(!openCollapse2)}
          style={{ borderBottom: openCollapse2 ? '' : '1px solid #D2D2D2' }}
        >
          <div className='menu-text'>Resources</div>
          {openCollapse2
            ? (
              <img src={IconArrowDown} alt='arrow-down' />
              )
            : (
              <img src={IconArrowRight} alt='arrow-right' />
              )}
        </ListItem>
        <Collapse
          in={openCollapse2}
          timeout='auto'
          unmountOnExit
          style={{ borderBottom: openCollapse2 ? '1px solid #D2D2D2' : '' }}
        >
          <List component='div' disablePadding>
            <ListItem
              button
              className='ps-3'
              onClick={() => {
                setLocal('Resources')
                navigate(Paths.BLOG)
                onClose()
              }}
            >
              <div className='menu-sub-text ps-3'>Blog</div>
            </ListItem>
            <ListItem
              button
              className='ps-3 py-3'
              onClick={() => {
                setLocal('Resources')
                navigate(Paths.MEDIA)
                onClose()
              }}
            >
              <div className='menu-sub-text ps-3'>Media center</div>
            </ListItem>
            <ListItem
              button
              className='ps-3 py-3'
              onClick={() => {
                setLocal('Resources')
                navigate(Paths.FAQS)
                onClose()
              }}
            >
              <div className='menu-sub-text ps-3'>FAQs</div>
            </ListItem>
          </List>
        </Collapse>
        <Button
          variant='outlined'
          onClick={() => {
            navigate(location.pathname === Paths.CUSTOMERS ? Paths.SIGNUP : Paths.CONTACT)
            onClose()
          }}
          className={`${classes.buttonSecondaryBlack} button-mui mt-5`}
        >
          {location.pathname === Paths.CUSTOMERS ? 'Signup now' : 'Get a demo'}
        </Button>

        <Button
          variant='outlined'
          className={`${classes.buttonSecondaryWhite} d-md-inline button-mui mt-5`}
          onClick={() => {
            const createA = document.createElement('a')
            createA.setAttribute('href', 'https://platform.accesswages.com/login')
            createA.click()
          }}
        >
          Sign in
        </Button>
      </List>
    </Box>
  )

  if (location.pathname.slice(1) === 'how-it-works' && props.super === undefined) {
    return ''
  } else if (location.pathname.slice(1) === 'contact-us') return ''
  else {
    return (
      <div className='row py-3 navbar-fixed bg-navbar-hiw'>
        <div className='col-1 col-xl-3 d-flex justify-content-start px-4 px-xl-5'>
          <Button onClick={() => navigate(Paths.HOME)}>
            <img alt='Logo' src={Logo} />
          </Button>
        </div>
        <div className='justify-content-evenly col-7 col-lg-8 col-xl-6 d-md-flex d-none ps-5 ps-lg-1'>
          <MenuListComponent
            local={local}
            setLocal={setLocal}
            title='For Companies'
            list={[
              { name: 'Benefits', link: Paths.BENEFITS },
              { name: 'How it works', link: Paths.HOW_IT_WORKS }
            ]}
          />
          <Button
            onClick={() => {
              setLocal('employees')
              navigate(Paths.EMPLOYEES)
            }}
            className={'button ' + (local === 'employees' ? 'button-active' : '')}
          >
            For Employees
          </Button>
          {B2C_FEATURE_ACTIVE && (
            <Button
              onClick={() => {
                setLocal('customers')
                if (isB2C) {
                  navigate(Paths.CUSTOMERS)
                } else {
                  const consumerURL = window.location.origin.replace(
                    window.location.hostname,
                    `${MULTIKRD_B2C_SUBDOMAIN}.${window.location.hostname}`
                  )
                  if (!window.location.origin.includes(MULTIKRD_B2C_SUBDOMAIN)) {
                    window.location.replace(consumerURL)
                  }
                }
              }}
              className={'button ' + (local === 'customers' ? 'button-active' : '')}
            >
              For direct users
            </Button>
          )}
          <Button
            onClick={() => {
              setLocal('about-us')
              navigate(Paths.ABOUT_US)
            }}
            className={'button ' + (local === 'about-us' ? 'button-active' : '')}
          >
            About us
          </Button>
          <MenuListComponent
            local={local}
            setLocal={setLocal}
            title='Resources'
            list={[
              { name: 'Blog', link: Paths.BLOG },
              { name: 'Media Center', link: Paths.MEDIA },
              { name: 'FAQs', link: Paths.FAQS }
            ]}
          />
        </div>
        <div className='col d-flex d-md-inline justify-content-end'>
          <Button
            variant='outlined'
            onClick={() =>
              navigate(location.pathname === Paths.CUSTOMERS ? Paths.SIGNUP : Paths.CONTACT)}
            className={`${classes.buttonSecondaryBlack} button-mui`}
          >
            {location.pathname === Paths.CUSTOMERS ? 'Signup now' : 'Get a demo'}
          </Button>
          <Button
            variant='outlined'
            className={`${classes.buttonSecondaryWhite} d-md-inline button-mui`}
            onClick={() => {
              const createA = document.createElement('a')
              createA.setAttribute('href', 'https://platform.accesswages.com/login')
              createA.click()
            }}
          >
            Sign in
          </Button>
          <Button
            variant='outlined'
            onClick={() => setOpen(!open)}
            className='d-inline d-md-none'
            style={{ border: '0px' }}
          >
            <img src={IconMenu} alt='menu' />
          </Button>
        </div>

        <SwipeableDrawer
          anchor='right'
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          {MobileMenu()}
        </SwipeableDrawer>
      </div>
    )
  }
}
