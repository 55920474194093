import React from 'react'

export default function TermsAndConditions () {
  return (
    <div className='px-5 py-5 row'>
      <div className='col-12 pb-5'>
        <div className='row'>
          <div className='web-header pb-5'>TERMS AND CONDITIONS</div>
          <div className='text-regular text-start post'>
            Last Updated: January 11, 2023
            <br />
            <br />
            <div className='web-subhead'>1- Services.</div>
            Subject to Client’s payment of the applicable fees and charges and performance of its
            obligations under the Agreement, Multikrd agrees to provide: (1) the Multikrd Platform
            Services; (2) Implementation Services; (3) Training Services; (4) Professional
            Services,(5) Wage Access Program Services and (6) Tips on Demand Services (each as
            defined below) (hereinafter collectively referred to as the “Services”).
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>1.1 Multikrd Platform Services.&nbsp;</b>
              Subject to payment of the applicable fees specified in the Services Order (the
              “Subscription Fees”) and Client’s compliance the terms and conditions of this
              Agreement, Multikrd shall provide to Client, for use by the Client’s authorized users
              as specified in the applicable Services Order, access to and use of the software
              services described in the applicable Services Order (the “Multikrd Platform
              Services”). Client acknowledges and agrees that Client’s authorized users shall be
              limited to Client’s active employees and their use of the Multikrd Platform Services
              shall be limited to use during the period in which they are employed by Client, unless
              otherwise approved by Multikrd in writing.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>1.2 Implementation Services.&nbsp;</b>
              Multikrd shall provide the implementation services (“Implementation Services”) as
              specified in the applicable Services Order in accordance with the statement(s) of work
              (each a “Statement of Work”) accompanying the applicable Services Order.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>1.3 Training Services.&nbsp;</b>
              Multikrd shall provide the training services (“Training Services”) relating to access
              and use of the Multikrd Platform Services specified in the applicable Services Order
              and Statement of Work. Training Services shall be provided remotely via telephone or
              remote access to Client’s and/or its authorized users’ computers during Multikrd’s
              regular business hours unless otherwise specified in the applicable Services Order.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>1.4 Support Services; Other Professional Services.&nbsp;</b>
              Multikrd will provide basic support services to Client in accordance with Multikrd’s
              standard support procedures to address reported incidents of the Multikrd Platform
              Services not being accessible or not performing properly when used by Client’s
              authorized users in accordance with the Agreement and the Multikrd Platform Terms and
              Conditions of Use posted at
              https://multikrd.com/masterservicesagreement-termsandconditions/ (the “Multikrd
              Platform Terms of Use”) and all applicable documentation. Support services that are
              made necessary due to improper use, tampering or lack of proper configurations,
              settings, or other issues attributable to Client-end hardware, software or
              connectivity or otherwise not relating to issues with the Multikrd systems or the
              Multikrd Platform Services shall be chargeable by Multikrd and shall be paid for by
              Client at Multikrd’s then current service rates unless otherwise agreed to by
              Multikrd. Unless otherwise agreed to by the parties in a Services Order or Statement
              of Work, Multikrd shall have no obligation or responsibility with respect to
              maintenance, repair or support of any software or hardware not supplied by Multikrd.
              Except as expressly provided otherwise in the applicable Services Order, it shall be
              Client’s and its authorized users’ sole responsibility to maintain their own system
              security and protect their data, including virus protection, data backup and
              reasonable security procedures. Multikrd will also provide to Client other
              professional services (“Professional Services”) as Client may request from time to
              time, subject to the parties agreeing on the terms for such Professional Services,
              which shall be set forth in the applicable Services Order and Statement of Work. All
              Professional Services will be billed on a time and materials basis unless otherwise
              agreed to by the parties. The Statement of Work shall describe the project
              assumptions, specifications, scope, work plan, responsibilities, duration and fees for
              such Professional Services. Multikrd may subcontract all or a portion of the
              Professional Services to a qualified third party. In recognition that Multikrd’s
              personnel may perform similar services for third parties, nothing in the Agreement or
              a Work Order shall be deemed to prevent Multikrd from providing services or developing
              materials that may be perceived as competitive with those developed or provided
              hereunder.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>1.5 Wage Access Services.&nbsp;</b>
              Subject to Client’s performance of its obligations under the Agreement, Multikrd
              agrees to provide the Wage Access Program Services the “Wage Access Services”) as an
              online software service for access to and use by Client’s authorized and eligible
              employees located in the states where Multikrd provides the Wage Access Services, as
              specified in the applicable Services Order, via an integration with the Multikrd
              Platform Services. Client acknowledges and agrees that Client’s authorized users shall
              be limited to Client’s active employees who have earned and accrued wages that have
              not yet been paid to them as of the time of the applicable wage access transaction,
              and their use of the Services shall be limited to use during the period in which they
              are employed by Client, unless otherwise approved by Multikrd in writing.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>1.6 Tips on Demand Services.&nbsp;</b>
              Subject to Client’s performance of its obligations under the Agreement, Multikrd
              agrees to provide the Tips on Demand Program Services the “Tips on Demand Services”)
              as an online software service for reimbursement of tips collected by Client on behalf
              of its authorized and eligible employees located in the states where Multikrd provides
              the Tips on Demand Services, as specified in the applicable Services Order, via an
              integration with the Multikrd Platform Services. Multikrd will not be responsible to
              select eligible employees. Client acknowledges and agrees that Client’s authorized
              users shall be limited to Client’s active employees who have earned and accrued tips
              collected by Client and that have not yet been paid to them as of the time of the
              applicable tip on demand transaction, and their use of the Services shall be limited
              to use during the period in which they are employed by Client, unless otherwise
              approved by Multikrd in writing.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                1.7 Implementation Requirements for Provision of the Wage Access Services.&nbsp;
              </b>
              Depending on how Client’s “Employee Data” (as defined below) is provided to Multikrd
              for use in order to provide the Wage Access and/or Tips on Demand Services,
              implementation services may be necessary to integrate the Services with Client’s
              employee data systems. Employee Data may be exchanged either through secure encrypted
              data files or through an API integration between the Multikrd Platform Services
              software and Client’s Employee Data systems, which may be on Client-end systems or
              Client’s payroll and/or tips processing service provider systems. In the event that
              the parties determine to establish such an API integration, Client grants to Multikrd
              a license to use the Client API(s) for the purpose of exchanging Employee Data as
              necessary for the provision of the Wage Access and/or Tips on Demand Services. Client
              agrees to provide cooperation, access to Client’s and/or its payroll processing
              service provider’s Employee Data systems and assistance as reasonably necessary in
              order to implement and maintain such integration and/or otherwise provide the Employee
              Data necessary for the provision of the Wage Access and/or Tips on Demand Services.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                1.8 Client Responsibilities With Respect to Provision of Employee Data and Employee
                Authorization for Payroll Deductions and Tips Payments.&nbsp;
              </b>
              Client shall be solely responsible for providing to Multikrd the necessary employment
              data pertaining to its employees who use the Wage Access and/or Tips on Demand
              Services, including the following data: employee payroll deposit bank account
              information, paycheck, wage, tips, maximum amount of tips per employee and per pool of
              employees, salary and/or other compensation information, including whether the
              applicable employee is an hourly, exempt or non-exempt employee, employee pay dates
              for wage and tips and the amount of earned and accrued wages and/or tips as of when a
              wage access or tips on demand transaction is requested, as well as any other related
              information necessary for Multikrd to provide the Wage Access and/or Tips on Demand
              Services (collectively, the “Employee Data”). For Wage Access Services: (i) if the
              applicable Employee Data is provided via encrypted file transfer, Client shall provide
              the applicable Employee Data to Multikrd on a daily basis,(ii) if the applicable
              Employee Data is provided via an API integration, Client shall provide access to the
              Employee Data to Multikrd immediately upon request by Multikrd via the API integration
              with Client’s or Client’s payroll processing service provider’s Employee Data systems;
              in either case, Client shall provide up to date Employee Data expediently in order for
              Multikrd to process and approve or reject wage access transaction requests in a timely
              manner. For Tips on Demand Services: Client shall provide the applicable Employee Data
              periodically through the Multikrd Platform Services; Client agrees that a user will
              only have the tips available for Tips on Demand Services for the amount of tips
              approved by Client on its tips payment order through the Multikrd Platform Services.
              Client grants to Multikrd a license to receive, retrieve, process, administer,
              transmit and otherwise use the Employee Data for the purpose of providing the Wage
              Access and/or Tips on Demand Services as necessary for the Wage Access and/or Tips on
              Demand Services to be provided via the Multikrd Platform Services. Client represents
              and warrants that: (i) it owns or otherwise has all necessary rights to provide the
              Employee Data in accordance with this Agreement, (ii) the use of the Employee Data by
              Multikrd in accordance with this Agreement as necessary for the Wage Access and/or
              Tips on Demand Services to be provided via the Multikrd Platform, does not and shall
              not violate any privacy rights, contract rights, intellectual property rights, rights
              of publicity or other rights of any employee or other third party, (iii) Client has
              obtained and/or shall, before providing Employee Data, obtain from all employees that
              will use the Wage Access and/or Tips on Demand Services the signed written consent and
              any other authorizations required from each such employee (A) in order to provide all
              Employee Data to Multikrd for use in connection with the Wage Access and/or Tips on
              Demand Services via the Multikrd Platform, (B) for Multikrd to notify Client regarding
              the wage access transactions requested and processed for each employee, including the
              amounts accessed and applicable funds transfer processing charges (including expedited
              processing charges if expedited processing is requested) and wage access program
              participation charges in connection with the applicable wage access transaction, (C)
              for all wage access transaction amounts including any such charges to be deducted from
              the applicable employee’s wages and paid to Multikrd and (D) for all tips to be paid
              through the Multirkd Platform Services. Client shall maintain all such rights,
              consents and authorizations in effect at all times while the Services are provided to
              or used by Client and its employees. Client shall be solely responsible for ensuring
              that all Employee Data that is provided in accordance with this Agreement is up to
              date, complete and accurate in all respects, and shall promptly update the Employee
              Data and/or otherwise notify Multikrd in the event of any unauthorized access to or
              use of the Wage Access and/or Tips on Demand Services by Client employees, former
              employees or other third parties, or any change or anticipated change in the
              employment status of any Client employee, or any deductions, garnishments, liens or
              other actions with respect to any Client employee’s wages or tips that would impact
              Multikrd’s ability to receive repayment of any wage access or tips on demand
              transaction amounts and/or payment of any other amounts payable to Multikrd related
              thereto. Client shall be required to, and shall require that its payroll processing
              service provider and its authorized users’ maintain their own system security and
              protect their data, including virus protection, data backup and reasonable security
              procedures, and maintain all necessary configurations and integrations in order to be
              able to exchange Employee Data and make the required wage deductions, funds processing
              charges and program participation charges from Client’s employee wages to repay
              Multikrd for all wage access and tips on demand transactions made by Multikrd to
              Client’s employees.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                1.9 Client Responsibilities With Respect to Employee Payroll Deductions.&nbsp;
              </b>
              Client shall, and/or shall cause its payroll processing service provider to, deduct
              from Client’s employee’s wages and pay over to Multikrd, all wage access amounts paid
              by Multikrd to Client’s employees together with all any applicable funds transfer
              processing charges (including expedited processing charges if expedited processing is
              requested) and wage access program participation charges in connection with the
              applicable wage access transaction. Such deductions from employee wages shall be made
              from the next wage payments to the applicable employee after the applicable wage
              access transaction. If such next wage payment is not in an amount sufficient to repay
              to Multikrd all amounts due to Multikrd from the applicable employee for wage access
              transactions made by Multikird, the outstanding unpaid balance owed to Multikrd shall
              be deducted from the applicable employee’s next subsequent wage payment(s) until the
              amounts owed to Multikrd have been paid to Multikrd in full. Client shall make all
              necessary payroll processing configuration changes, deduction settings changes and
              provide all necessary instructions to its payroll/accounting personnel and payroll
              processing service providers to deduct from the applicable employees’ wages and pay to
              Multikrd the amounts accessed by Multirkd to Client employees as wage access
              transactions together with the amounts charged in connection therewith, including the
              applicable funds transfer processing charges (including expedited processing charges
              if expedited processing is requested) and wage access program participation charges in
              connection with the applicable wage access transaction. Multikrd shall provide its
              designated account for receipt of such payments from Client.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                1.10 Reporting/Reconciliation of Wage Access Transactions and Repayments.&nbsp;
              </b>
              Upon request, Multikrd shall provide to Client a statement of all wage access
              transactions completed during the prior week for Client’s employees, and the repayment
              amounts owed to Multikrd for each wage access transaction completed during such week,
              as well as any repayment amounts that are still owed to Multikrd with respect to wage
              access transactions in prior periods. Client shall ensure that all such repayment
              amounts are programmed into its employee payroll processing systems in order to make
              the necessary payroll deductions from Client employee wages to pay Multikrd for such
              wage access transactions in its next payroll payments to the applicable employees.
              Client shall provide a report to Multikrd when it makes its payroll payments to its
              employees specifying for each employee that received wage access funds from Multikrd
              the amount of wages payable to such employee and the amount deducted from such wages
              to repay Multikrd for the amounts owed by such employee to Multikrd for wage access
              transactions made prior to such date, and the amount remaining unpaid to Multikrd, if
              any. In the event of any discrepancy between the information provided by Client and
              Multikrd’s records, the parties shall cooperate reasonably to determine the cause for
              the discrepancy and upon such determination, the parties shall adjust their records
              accordingly to reconcile any such discrepancies in a timely manner, and in any event
              before the Client’s then next payroll cycle.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                1.11 Client Responsibilities With Respect to Employee Tips Repayment.&nbsp;
              </b>
              Client agrees that all tips paid to the client’s employees by Multikrd through the
              Tips on Demand Services will be repaid to Multikrd together with all any applicable
              funds transfer processing charges (including expedited processing charges if expedited
              processing is requested) and tips on demand program participation charges in
              connection with the applicable tips on demand transaction. Each repayment from
              employee tips payment shall be made two business days after Client instruct Multikrd
              to pay the tips to its eligible employees through the Multikrd Portal. Client shall
              update periodically the amount of tips that Multikrd will pay on behalf of Client to
              all applicable employees. Client understands the employees will only be able to
              request a tips on demand transactions for the amounts previously approved by Client
              with the periodicity on which Client updates payment order for tips on Multikrd’s
              platform. Client will make all necessary tips processing configuration changes,
              deduction settings changes and provide all necessary instructions to its
              tips/accounting personnel to deduct from the applicable employees’ tips and pay to
              Multikrd the amounts paid by Multirkd to Client employees as tips on demand
              transactions together with the amounts charged in connection therewith, including the
              applicable funds transfer processing charges (including expedited processing charges
              if expedited processing is requested) and tips on demand program participation charges
              in connection with the applicable tips on demand transaction. Multikrd shall provide
              its designated account for receipt of such payments from Client.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                1.12 Reporting/Reconciliation of Tips on Demand Transactions and Repayments.&nbsp;
              </b>
              Upon request, Multikrd shall provide to Client a statement of all tips on demand
              transactions completed during the prior week for Client’s employees, and the repayment
              amounts owed to Multikrd for each tips on demand transaction completed during such
              week, as well as any repayment amounts that are still owed to Multikrd with respect to
              tips on demand transactions in prior periods, if any. Client shall ensure that all its
              eligible employees consent that their tips will be paid by Multikrd. Client shall
              provide a report to Multikrd when it makes its tips payments to its employees
              specifying for each employee that received tips on demand funds from Multikrd the
              amount of tips payable to such employee and the amount owed from Client to repay
              Multikrd for tips on demand transactions made prior to such date, and the amount
              remaining unpaid to Multikrd, if any. In the event of any discrepancy between the
              information provided by Client and Multikrd’s records, the parties shall cooperate
              reasonably to determine the cause for the discrepancy and upon such determination, the
              parties shall adjust their records accordingly to reconcile any such discrepancies in
              a timely manner, and in any event before the Client’s then next payroll cycle.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>1.13 Exclusions from the Service.&nbsp;</b>
              The Services do not include, and Multikrd shall not be responsible for, the following
              in the provision of the Multikrd Platform Services, other than the Implementation
              Services described in the Services Order(s) and Statement(s) of Work to the Agreement:
              (a) the provision of Client or User-end hardware or non-Multikrd software required for
              access to and use of the Multikrd Platform Services via the Internet, or any
              Professional Services required to manage such hardware and non-Multikrd software; (b)
              services to modify or extend the scope of the Multikrd Platform Services; (c)
              assistance to resolve Multikrd Platform Services problems or errors that are not
              within the scope of the support services as described in Multikrd’s standard support
              terms; (d) modification to the Multikrd Platform Services configuration, including
              without limitation, the following: (i) modification to the connectivity configuration
              for on-premise and cloud-based applications, including without limitation, changing
              the IP address or application credentials; (ii) modification to Client’s existing
              policies and roles for who has access to each resource, password rules or approvers;
              (iii) account reconciliation for new groups of users who are being added to the
              Multikrd Platform Services; (iv) adding a new connected system or application to the
              Multikrd Platform Services; and (v) modifying the configuration of the user interface,
              including the appearance, text, branding or other features.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                1.14 Additional Multikrd Platform Services, Professional Services and/or Authorized
                Users.&nbsp;
              </b>
              Client may purchase additional Multikrd Platform Services or Professional Services
              and/or add authorized users for use of the Multikrd Platform Services under the terms
              of the Agreement at then current pricing or such other pricing as may be mutually
              agreed to by Multikrd and Client by contacting Multikrd and completing a Services
              Order or amendment to Services Order and paying the applicable fees and charges.
            </div>
            <br />
            <br />
            <div className='web-subhead'>2- Fees and Billing.</div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>2.1 Fees.&nbsp;</b>
              For any Service except for the Wage Access and Tips on Demand Services as provided in
              Section 2.3 and Section 2.4 below, Client shall pay all fees specified in the Services
              Order in accordance with the payment terms set forth herein unless other payment terms
              are specified in the applicable Services Order. All fees are non-refundable unless
              expressly agreed otherwise.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                2.2 Billing and Payment Terms for Services other than the Wage Access and Tips on
                Demand Services.&nbsp;
              </b>
              Unless otherwise specified in the applicable Services Order, all upfront fees are due
              on the Effective Date, Subscription Fees are invoiced, payable and due in advance of
              the applicable month or year, as applicable, and other fees shall be invoiced in
              arrears at the beginning of every calendar month and shall be due within thirty (30)
              days after the invoice date. All payments must be made in U.S. Dollars. Late payments
              hereunder will accrue interest at a rate of 1½% per month, or the highest rate allowed
              by applicable law, whichever is lower. In the event of non-payment (subject to the
              cure period in Section 8.2(b)), Multikrd may suspend or terminate access to and/or use
              or provision of the Services upon notice to Client. Multikrd reserves the right to
              make changes to fees, prices and other billing and payment terms upon at least sixty
              (60) days’ written notice.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>2.3 Wage Access Service Fees.&nbsp;</b>
              Repayment of the wage access transaction amounts and payment of the applicable funds
              transfer processing fees, including any expedited processing fees if expedited
              processing is requested, and wage access program participation charges for wage access
              transactions made by Multikrd to Client’s employees will be paid by Client to Multikrd
              via deductions from the applicable employees’ wages as described in Section 1.5 above,
              except as otherwise provided in Section 2.5 below. The amount of such charges shall be
              specified in the reports provided by Multikrd to Client in accordance with Section 1.7
              above. All amounts paid to Multikrd hereunder are non-refundable unless expressly
              agreed otherwise.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>2.4 Tips on Demand Service Fees.&nbsp;</b>
              Repayment of the tips on demand transaction amounts and payment of the applicable
              funds transfer processing fees, including any expedited processing fees if expedited
              processing is requested, and tips on demand program participation charges for tips on
              demand transactions made by Multikrd to Client’s employees will be paid by Client to
              Multikrd via deductions from the applicable tips payments as described in Section 1.6
              above, except as otherwise provided in Section 2.5 below. The amount of such charges
              shall be specified in the reports provided by Multikrd to Client in accordance with
              Section 1.7 above. All amounts paid to Multikrd hereunder are non-refundable unless
              expressly agreed otherwise.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>2.5 Taxes.&nbsp;</b>
              If any federal, state, local or foreign sales, use, property, value-added, excise or
              gross receipts taxes or any other taxes or other governmental charges of any kind
              (other than Multikrd’s income taxes) are imposed or are otherwise payable with respect
              to any access to or use of the Multikrd Platform Services or any license, software,
              hardware or other goods or Implementation, Training, support, Wage Access Service,
              Tips on Demand Service or other Professional Services provided under the Agreement,
              then such taxes and other charges shall be billed to and paid by Client. As between
              Multikrd and Client, Client shall be solely responsible for payment all such taxes and
              other governmental charges. If Client is exempt from payment of any taxes, Client is
              responsible for providing Multikrd with a valid tax exemption or direct pay
              certificate for same; otherwise Client remains responsible for all such taxes and
              other governmental charges. In connection with the Wage Access and Tips on Demand
              Services, Client shall be solely responsible for determining whether, how, when and
              how much to withhold with respect to its employee wage payments for payroll taxes and
              any other amounts to be withheld from its employee wage and tips payments with respect
              to any amounts that are provided to Client’s employees as wage access and/or tips on
              demand transactions and the related funds processing, expedited processing, wage
              access and tips on demand program participation charges and any other amounts payable
              to Multikrd, and shall indemnify Multikrd and its affiliates with respect to any
              obligations, claims or liability of any kind with respect thereto.
            </div>
            <br />
            <br />
            <div className='web-subhead'>3- Client’s Obligations.</div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>3.1 User Compliance.&nbsp;</b>
              Client shall: (a) be solely responsible for all of Client’s users’ compliance with the
              Agreement and shall comply and cause its users to comply with all applicable laws in
              their conduct of their business and their use of the Services; (b) be solely
              responsible for the accuracy, integrity, and legality of Client data and Employee Data
              and of the means by which it acquires and enters Client data and Employee Data; (c)
              use the Services only in accordance with all documentation provided with the Services
              and all applicable laws and regulations; and (d) notify Multikrd immediately of any
              unauthorized use of any password, account, copying or access to the Services. Any
              failure of Client’s users to comply with the terms of the Agreement and the Multikrd
              Platform Terms of Use shall constitute a material breach of the Agreement by Client.
              Client will maintain industry standard organizational and technical security
              safeguards for data accessed, stored, collected, provided or processed by Client and
              Client’s users through the Services. All users shall agree to the Multikrd Platform
              Terms of Use posted online at https://www.multikrd.com/legal-agreements, as may be
              updated or amended from time to time, by clicking to agree to same as part of their
              user registration process and elsewhere and in other instances where prompted to do so
              on the Multikrd Platform Services. The Multikrd Platform Terms of Use are incorporated
              herein by this reference and are made a part of this Agreement.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>3.2 Prohibited Uses.&nbsp;</b>
              Client shall not, and shall not permit its authorized users to, directly or
              indirectly: (a) send or store spam, unlawful, infringing, obscene, or libelous
              material, or Malicious Code (as defined below) through the Services; (b) sublicense,
              resell, rent, lease, distribute, market, provide service bureau services or other
              software services to third parties using or based upon the Services, or commercialize
              or otherwise transfer or provide rights with respect to, or access to or usage of the
              Services; (c) remove or alter any copyright, trademark or proprietary notice in the
              Services; (d) reverse engineer, decompile or modify the Services or any component
              thereof; (e) copy any ideas, features, functions or graphics of the Services or create
              a product or service using the same or similar ideas, features, functions or graphics
              as those in the Services or otherwise use or cause to be used the Services for
              gathering of information to develop or provide services that compete with or are
              substitute of the Services; (f) conduct automated functionality tests or load tests on
              the Services; (g) create Internet links to the Services; or (h) "frame," “fork” or
              "mirror" any part of the Services on any other device. Multikrd may terminate this
              Agreement immediately if Client or its authorized users violate this Section 3.2.
              “Malicious Code” means viruses, worms, time bombs, Trojan horses ransomware and/or
              other harmful or malicious code, files, scripts, agents, or programs. Client agrees
              that Multikrd shall have the right to suspend, refuse or cease providing the Services
              to any user at any time and/or with respect to any particular Wage Access Service
              transaction request as it determines in its sole and absolute discretion.
            </div>
            <br />
            <br />
            <div className='web-subhead'>4- Confidential Information.</div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>4.1 Confidential Information.&nbsp;</b>
              Each party acknowledges that it will have access to certain confidential information
              of the other party concerning the other party’s business, plans, customers,
              technology, products and services, and including the terms and conditions of this
              Agreement (collectively, “Confidential Information”). For avoidance of doubt, the
              Services and the software and technology, know-how, trade secrets and proprietary
              information used and/or embodied therein, and any analytics or work product created by
              or licensed to Multikrd resulting therefrom is and shall be Confidential Information
              belonging to Multikrd or its licensors. As between Client and Multikrd, the Employee
              Data is Confidential Information belonging to Client. Each party shall not use in any
              way, for its own account or the account of any third party, except as expressly
              permitted by this Agreement or its privacy policy, nor disclose to any third party
              (except as required by law or to that party’s attorneys, accountants and other
              advisors as reasonably necessary to provide the Services), any of the other party’s
              Confidential Information and shall take reasonable precautions to protect the
              confidentiality of such information.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>4.2 Exceptions.&nbsp;</b>
              Information will not be deemed Confidential Information if such information: (a) is
              known to the receiving party prior to receipt from the disclosing party directly or
              indirectly from a source other than one having an obligation of confidentiality to the
              disclosing party; (b) becomes known (independently of disclosure by the disclosing
              party) to the receiving party directly or indirectly from a source other than one
              having an obligation of confidentiality to the disclosing party that is known by the
              receiving party; (c) becomes publicly known or otherwise ceases to be secret or
              confidential, except through a breach of this Agreement by the receiving party; or (d)
              is independently developed by the receiving party without use of the disclosing
              party’s Confidential Information.
            </div>
            <br />
            <br />
            <div className='web-subhead'>5- Proprietary Rights</div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>5.1 Multikrd Intellectual Property.&nbsp;</b>
              Multikrd and/or its licensors own the “Multikrd Intellectual Property” (as defined
              below) in and to the Services and the software and technology used and/or embodied
              therein, and, in each case, any modifications, enhancements or improvements thereto
              created by or for Multikrd. This Agreement does not convey or transfer any ownership
              rights in any Multikrd Intellectual Property or any software or technology used and/or
              embodied in the Services. The Multikrd name, logo, and trade names are trademarks of
              Multikrd and no right is granted to use them except as expressly granted herein.
              Multikrd and/or its licensors reserve all rights, title, and interest in and to the
              Services, including, without limitation, all software (including object code and
              source code), algorithms, databases, inventions, works of authorship, trade secrets
              and other Multikrd Intellectual Property. “Multikrd Intellectual Property” means any
              patents and patent applications, copyrights, trademarks, service marks and any
              applications or registrations for same, trade names, domain name rights, trade secret
              rights, and all other intellectual property rights with respect to Multikrd products,
              Services, software or other works of authorship and/or other Multikrd assets. Client
              acknowledges and agrees that, except for the online access and usage authorization
              provided by Multikrd to Client with respect to the Multikrd Platform Services
              specified in the Services Order, it shall not acquire or otherwise have any right,
              title or interest in and to any of the Services or any other software or other work of
              authorship, invention, concept, process, trade secret, proprietary information,
              trademark, service mark or other intellectual property or work product developed by
              Multikrd independently or by Multikrd with Client’s input (“Multikrd Work Product”).
              Under no circumstances shall the disclosure of any such Multikrd Work Product or
              delivery of any copy of any Multikrd Work Product by Multikrd to Client be construed
              as a transfer of title to that copy or a transfer of any right, title or interest in
              such Multikrd Work Product, except for the usage authorization granted to Client as
              set forth in the Agreement. Client is not authorized to, and shall not undertake, to
              create any system, service, product, software or other work of authorship, invention,
              concept, process, trade secret, proprietary information or other intellectual property
              based upon, copying or otherwise including or using functions, features, style, form
              or other elements of the Multikrd Platform Services or other Multikrd Intellectual
              Property. To the extent that, notwithstanding the foregoing, Client employees or
              contractors conceive of, develop or otherwise create, whether separately or jointly
              with Multikrd, any such software or other work of authorship, invention, concept,
              process, trade secret, proprietary information, data, or other intellectual property,
              or work product based upon, copying or otherwise including or using functions,
              features, style, form or other elements of the Multikrd Platform Services or other
              Multikrd Intellectual Property (“Client Work Product”) then Client shall assign and
              hereby does assign, and shall cause its employees and contractors to assign, to
              Multikrd all rights, title and interests with respect to such Client Work Product,
              and, to the extent necessary for Client to use the Multikrd Platform Services as
              contemplated hereunder, such Client Work Product shall be deemed to form part of the
              Multikrd Platform Services or other Multikrd software provided for use as a service to
              Client subject to the terms and conditions of the Agreement. It is recognized and
              understood that Multikrd may develop new software or modify its existing software
              based upon the suggestions and recommendations provided by Client, and that except as
              otherwise expressly agreed to by Multikrd in writing signed by a duly authorized
              officer of Multikrd, Client shall have no right, claim or interest in such new or
              modified software developed by Multikrd.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                5.2 Ownership of Client and Employee Data; License of Client Data for Analytical
                Purposes; Ownership of Analytics Data.&nbsp;
              </b>
              Client shall own the intellectual property rights, if any, in and to Employee Data.
              Client hereby grants to Multikrd a non-exclusive, worldwide, royalty-free right and
              license to receive, retrieve, process, administer, transmit and otherwise use any
              Client data, Employee Data or content as necessary to provide the Services in
              accordance with the Agreement or as required by court order, applicable law or other
              legal requirement. Additionally, Client hereby grants to Multikrd an irrevocable,
              perpetual, worldwide, royalty-free, fully paid transferable and sublicensable license
              and right to use Client and Employee Data for analytical purposes. Multikrd may also
              use Client employee personally identifiable information in accordance with the
              Multikrd Privacy Policy which is posted online at
              https://www.multikrd.com/public/legal-agreements/privacy-policy, as may be amended or
              modified from time to time, and Client’s employees are required to agree to and
              consent to said Privacy Policy as a condition to their use of the Services. Multikrd
              shall own and have the right to gather, retrieve, compile, store, retain, use, sell,
              license, transfer or otherwise exploit all information that is not personally
              identifiable information for research, quality control, product development and
              refinement, commercial and other purposes as determined by Multikrd without a duty to
              account to or obtain consent from Client or Client’s authorized users or any third
              party. As used herein, “personally identifiable information” is any data element or
              collection of data elements that can be associated with a specific individual, whether
              by itself or in combination with other information. Multikrd may use such information
              as may be reasonably necessary in connection with performing, providing, developing,
              enhancing, supporting, and maintaining the Services; and (ii) in connection with the
              creation of any information or data derived from use of the Services (including,
              without limitation, metrics and analytics related to such use), which does not
              identify a specific person, including as may be required to develop, deliver and
              provide ongoing innovation to the Services. Multikrd shall own all intellectual
              property rights with respect to any analytics and analytical data, work of authorship
              or other work product created or otherwise acquired based on analysis of Client data
              by Multikrd or its contractors.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>5.3 Suggestions.&nbsp;</b>
              Client hereby grants and shall cause its users to grant to Multikrd an irrevocable,
              perpetual, worldwide, royalty-free, fully paid transferable and sublicensable license
              and right to use, copy, modify, or distribute, including by incorporating into the
              Services any suggestions, enhancement requests, recommendations or other feedback
              provided by Client, including Client’s authorized users, relating to the Services, and
              shall exclusively own all intellectual property rights in and to all software,
              technology, inventions, works of authorship and other developments created by Multikrd
              based on same without any duty to compensate or otherwise account to Client, Client
              employees or its users with respect thereto.
            </div>
            <br />
            <br />
            <div className='web-subhead'>6- Representations and Warranties.</div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>6.1 Mutual Representations and Warranties.&nbsp;</b>
              Each party represents and warrants to the other party that it has all necessary power,
              right and authority to enter into this Agreement and perform its obligations
              hereunder, and that its entering into this Agreement does not violate the terms of any
              agreement between it and any third party.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>6.2 Client Representations and Warranties.&nbsp;</b>
              In addition to Client’s representations, warranties and obligation elsewhere in this
              Agreement, Client represents, warrants and covenants to Multikrd that: (i) Client owns
              all right, title and interest in and to, and/or has full and sufficient authority to
              use and provide access to Multikrd to all Client systems, software, materials or data
              (including all Employee Data) furnished by Client or its authorized users as
              contemplated for the provision of the Services; (ii) Client will secure, and comply
              with the terms and conditions of, any licensing or other agreements which govern the
              use of any third party software, data or other materials or intellectual property used
              in or forming part of Client systems, software, materials or data; (iii) the Client’s
              systems, software, materials and/or data (including all Employee Data) do not and will
              not infringe the patent, copyright, trademark or other intellectual property rights of
              any party, or constitute libel, slander, defamation, invasion of privacy, or violation
              of any right of publicity or any other third party rights; (iv) Client has or will
              obtain all necessary consents, permissions, clearances, authorizations and waivers
              (including any of the foregoing required from its employees and other authorized
              users) for the access to and use of the Client’s systems, software, materials and data
              as contemplated hereunder, including all of the foregoing required to transfer and/or
              use such data from Client and/or or its authorized users’ or other systems to and from
              Multikrd systems in connection with providing the Services and all consents,
              permissions and authorizations from its employees to provide their Employee Data and
              any other employee-related information to Multikrd, and to deduct from their salary,
              wages, tips and/or other compensation the amounts provided to them by Multikrd via
              Wage Access and Tips on Demand Service transactions together with any funds transfer
              processing charges and wage access program participation charges and any other charges
              related thereto; and (v) Client is presently in compliance with, has complied and will
              comply with all laws, rules, regulations and other legal requirements in the conduct
              of its business and with respect to Client’s use of the Services and Client’s systems,
              software, materials or data, including all Employee Data.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>6.3 Multikrd Representations and Warranties.&nbsp;</b>
              Multikrd represents and warrants that the Services shall substantially conform to the
              applicable descriptions and specifications for same as described herein or in the
              Multikrd Terms and Conditions. Multikrd does not warrant the correctness,
              completeness, merchantability or fitness for a particular purpose of any such data
              provided in connection with the Services, and Client shall indemnify, defend and hold
              the Multikrd Indemnitees harmless from any and all claims arising out of such data or
              its use. Client shall be solely responsible for the accuracy and completeness of all
              Employee Data and any other data provided by Client or its authorized users in
              connection with the Services. Client acknowledges and agrees that its sole remedy for
              breach of the foregoing representations and warranties shall be support services to
              correct nonconformities or a refund of amounts paid for the nonconforming Services.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>6.4 Indemnification; Breach of Warranties.&nbsp;</b>
              Client shall indemnify and defend Multikrd, its affiliates and their respective
              employees, officers, managers, directors, shareholders, agents, contractors and
              representatives (collectively, the “Multikrd Indemnitees'') and hold the Multikrd
              Indemnitees harmless from and against any and judgments, losses, costs (including
              court costs and reasonable attorneys’ fees), damages, settlements, suits, actions,
              expenses, liabilities, taxes, fines and claims asserted against, sustained, or
              suffered by or involving the Multikrd Indemnitees arising out of or resulting from (i)
              any breach by Client of its representations, warranties or obligations hereunder;
              and/or (ii) any claims made by Client’s employees, any other authorized users or other
              third parties arising out of Client’s or its employees and/or other authorized users’
              use of or access to the Services or related to any other Services provided by
              Multikrd. In the event of any breach, or anticipated breach, of any of Client’s
              warranties herein, in addition to any other remedies available at law or in equity,
              Multikrd will have the right to immediately, in Multikrd’s sole discretion, suspend
              access to, use or provision of the Multikrd Platform Services and/or any other
              Services if deemed reasonably necessary by Multikrd to prevent any liability for
              Multikrd. Client shall be solely responsible for the accuracy and completeness of all
              data (including Employee Data) provided by Client or its authorized users in
              connection with the Services.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>6.5 Warranties and Disclaimers by Multikrd.&nbsp;</b>
              EXCEPT AS EXPRESSLY PROVIDED OTHERWISE HEREIN, THE SERVICES ARE PROVIDED “AS IS,” AND
              CLIENT’S AND ITS AUTHORIZED USERS’ USE OF THE SERVICES IS AT ITS/THEIR OWN RISK.
              MULTIKRD DOES NOT MAKE, AND HEREBY DISCLAIMS, ANY AND ALL OTHER EXPRESS OR IMPLIED
              WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE, NONINFRINGEMENT AND TITLE, AND ANY WARRANTIES ARISING FROM A
              COURSE OF DEALING, USAGE, OR TRADE PRACTICE. MULTIKRD DOES NOT WARRANT THAT THE
              SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR COMPLETELY SECURE.
            </div>
            <br />
            <br />
            <div className='web-subhead'>7- Limitations of Liability.</div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>7.1 Exclusions.&nbsp;</b>
              MULTIKRD WILL NOT BE LIABLE TO THE CLIENT, ITS EMPLOYEES OR ANY OTHER AUTHORIZED USERS
              OR ANY THIRD PARTY FOR ANY LOST REVENUE, LOST PROFITS, REPLACEMENT GOODS, LOSS OF
              TECHNOLOGY, RIGHTS OR SERVICES, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT OR
              CONSEQUENTIAL DAMAGES, LOSS OF DATA, OR INTERRUPTION OF BUSINESS, EVEN IF MULTIKRD WAS
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER UNDER THEORY OF CONTRACT, TORT
              (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>7.2 Maximum Liability.&nbsp;</b>
              MULTIKRD’S MAXIMUM AGGREGATE LIABILITY FOR ANY CLAIMS RELATED TO OR IN CONNECTION WITH
              THIS AGREEMENT AND/OR THE SERVICES, EXCEPT FOR THE WAGE ACCESS SERVICE, WILL BE
              LIMITED TO THE TOTAL AMOUNT PAID BY CLIENT TO MULTIKRD HEREUNDER FOR THE PRIOR MONTHLY
              PERIOD TO MULTIKRD. IN CONNECTION WITH THE WAGE ACCESS AND TIPOS ON DEMAND SERVICES
              TRANSACTION(S) MULTIKARD’S MAXIMUM AGGREGAT LIABILITY FOR ANY CLAIMS WILL BE LIMITED
              TO THE TOTAL AMOUNT PAID TO MULTIKRD THAT IS/ARE THE SUBJECT OF THE APPLICABLE
              CLAIM(S), OR THE TOTAL AMOUNT OF WAGE ACCESS AND TIPS ON DEMAND PROGRAM PARTICIPATION
              CHARGES PAID TO MULTIKRD FOR THE SERVICES PROVIDED IN THE THEN PRIOR MONTH, WHICHEVER
              IS LESS.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                7.3 Basis of the Bargain; Failure of Essential Purpose.&nbsp;
              </b>
              Client acknowledges that Multikrd has set its terms and charges and entered into this
              Agreement in reliance upon the limitations of liability and the disclaimers of
              warranties and damages set forth herein, and that the same form an essential basis of
              the bargain between the parties. The parties agree that the limitations and exclusions
              of liability and disclaimers specified in this Agreement will survive and apply even
              if found to have failed of their essential purpose.
            </div>
            <br />
            <br />
            <div className='web-subhead'>8- Term and Termination.</div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>8.1 Term.&nbsp;</b>
              This Agreement will be effective commencing on the Effective Date, and continue until
              the expiration of the last terminated or expiring Services Order unless terminated
              sooner.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>8.2 Termination.&nbsp;</b>
              This Agreement will be effective commencing on the Effective Date, and continue until
              the expiration of the last terminated or expiring Services Order unless terminated
              sooner.
            </div>
            <br />
            <div className='px-5'>
              <b className='medium px-5'>(a) For Convenience.&nbsp;</b>
              Multikrd may terminate this Agreement and cease providing the Services at any time
              upon no less than two (2) month’s prior written notice for any reason or no reason.
            </div>
            <br />
            <div className='px-5'>
              <b className='medium px-5'>(b) For Cause.&nbsp;</b>
              Either party will have the right to terminate this Agreement, or the applicable
              Services Order, if the other party breaches any material term or condition of this
              Agreement and fails to cure such breach within thirty (30) days after delivery of
              written notice of the same by the non-breaching party to the other party, except in
              the case of Client’s failure to pay fees, which must be cured within fifteen (15) days
              after receipt of written notice from Multikrd. Either party may also terminate this
              Agreement upon providing written notice thereof to the other party if: (i) the other
              party becomes the subject of a voluntary petition in bankruptcy or any voluntary
              proceeding relating to insolvency, receivership, liquidation, or composition for the
              benefit of creditors; (ii) the other party becomes the subject of an involuntary
              petition in bankruptcy or any involuntary proceeding relating to insolvency,
              receivership, liquidation, or composition for the benefit of creditors, if such
              petition or proceeding is not dismissed within sixty (60) days of filing; or (iii)
              Multikrd determines in its sole and absolute discretion that the continued provision
              of the Services would violate any applicable laws or other legal requirements.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>8.3 Effect of Termination.&nbsp;</b>
              Upon the effective date of expiration or termination of this Agreement: (a) Multikrd
              may immediately cease providing Services; (b) any and all payment obligations of
              Client under this Agreement will become due immediately; (c) within thirty (30) days
              after such expiration or termination, each party shall return all Confidential
              Information of the other party in its possession at the time of expiration or
              termination and shall not make or retain any copies of such Confidential Information
              except as required to comply with any applicable legal or accounting record keeping
              requirement. Client shall be required to pay the Subscription Fees for the
              subscription period that would remain had the Agreement not been terminated, unless
              the Agreement is terminated by Client based on an uncured material breach by Multikrd
              or by Multikrd for convenience.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>8.4 Survival.&nbsp;</b>
              The following provisions will survive any expiration or termination of the Agreement:
              Sections 1.5, 1.6, 1.7, 1.8 2.3, 3.2, 4-7, 8.3, 8.4 and 9.
            </div>
            <br />
            <br />
            <div className='web-subhead'>9- Miscellaneous Provisions.</div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>9.1 Force Majeure.&nbsp;</b>
              Except for the obligation to pay subscription fees and other amounts payable by Client
              to Multikrd hereunder, neither party will be liable for any failure or delay in its
              performance under this Agreement due to any cause beyond its reasonable control,
              including acts of war, acts of God, epidemics, pandemics or government ordered
              shutdowns, earthquakes, hurricanes, tornadoes or other windstorms, other storms or
              other elements of nature, embargo, riot, protests, civil disturbances, looting,
              sabotage, terrorism, labor shortage or dispute or other industrial disturbances,
              systemic electrical, telecommunications, or other utility failures, governmental act
              or failure of the Internet, provided that the delayed party: (a) gives the other party
              prompt notice of such cause, and (b) uses its commercially reasonable efforts to
              correct promptly such failure or delay in performance.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>9.2 Government Regulations.&nbsp;</b>
              Client shall not export, re-export, transfer, or make available, whether directly or
              indirectly, any Service, regulated item or information to anyone outside the U.S. in
              connection with this Agreement without first complying with all export control laws
              and regulations which may be imposed by the U.S. Government and any country or
              organization of nations within whose jurisdiction Client operates or does business.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>
                9.3 Governing Law; Dispute Resolution, Severability; Waiver.&nbsp;
              </b>
              The Agreement shall be governed by and construed in accordance with the laws of the
              State of Texas without application of conflicts of laws rules or principles. Any
              dispute relating to the terms, interpretation or performance of the Agreement (other
              than claims for injunctive relief or other equitable remedies) will be submitted at
              the request of either party to binding arbitration. Arbitration will be conducted in
              Houston, Texas, under the Commercial Arbitration Rules and procedures of the American
              Arbitration Association. The parties will either select one mutually acceptable
              arbitrator or, if the parties do not agree to a single arbitrator, each party shall
              select one arbitrator and the two arbitrators selected by the parties shall select a
              third arbitrator, and the arbitration shall be held before the three arbitrators, and
              shall be decided by vote of the three arbitrators with a vote of the majority of the
              arbitrators required for a decision. Any claims for injunctive or other equitable
              relief shall be brought and heard in the state or federal courts located in Houston,
              Texas, and Client consents to such venue and personal jurisdiction therein for any
              such proceedings. CLIENT HEREBY IRREVOCABLY WAIVES THE RIGHT TO A JURY TRIAL AS WELL
              AS THE RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS MEMBER ON ANY CLASS
              CLAIM AGAINST MULTIKRD, INCLUDING ANY RIGHT TO CLASS ARBITRATION OR ANY CONSOLIDATION
              OF INDIVIDUAL ARBITRATIONS. A printed version of the Agreement and of any notice given
              in electronic form shall be admissible in any legal proceedings based upon or relating
              to the Agreement to the same extent and subject to the same conditions as other
              business documents and records originally generated and maintained in printed form. In
              the event any provision of this Agreement is held by a court or arbitrator to be
              contrary to the law, the remaining provisions of this Agreement will remain in full
              force and effect. The waiver of any breach or default of this Agreement will not
              constitute a waiver of any subsequent breach or default, and will not act to amend or
              negate the rights of the waiving party.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>9.4 Assignment.&nbsp;</b>
              Client may not assign its rights or delegate its duties under this Agreement either in
              whole or in part without the prior written consent of Multikrd. Any attempted
              assignment or delegation without such consent will be void. Multikrd may assign this
              Agreement in whole or part to an affiliate or in connection with a merger or sale of
              all or substantially all of its assets. This Agreement will bind and inure to the
              benefit of each party’s successors and permitted assigns.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>9.5 Notices.&nbsp;</b>
              Any notice or communication required or permitted to be given hereunder may be
              delivered by hand, deposited with an overnight courier, or mailed by registered or
              certified mail, return receipt requested; postage prepaid to the address for the
              applicable party indicated in the first page of the Agreement, or at such other
              address as may hereafter be furnished in writing by either party hereto to the other.
              Such notice will be deemed to have been given as of the date it is delivered, mailed
              or sent, whichever is earlier. Notwithstanding the above, notices may be sent by email
              from Multikrd to Client or may be posted by Multikrd via the Services online on
              Client’s account and shall be effective upon sending or posting.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>9.6 Relationship of Parties.&nbsp;</b>
              Multikrd and Client are independent contractors and this Agreement will not establish
              any relationship of partnership, joint venture, employment, franchise or agency
              between Multikrd and Client. Neither Multikrd nor Client will have the power to bind
              the other or incur obligations on the other’s behalf without the other’s prior written
              consent, except as otherwise expressly provided herein.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>9.7 U.S. Government-Restricted Rights.&nbsp;</b>
              The software and accompanying documentation are deemed to be “commercial computer
              Software” and “commercial computer Software documentation,” respectively, pursuant to
              DFAR Section 227.7202 and FAR Section 12.212, as applicable. Any use, modification,
              reproduction release, performance, display or disclosure of the Software and
              accompanying documentation by the U.S. Government will be governed solely by the terms
              of this Agreement and will be prohibited except to the extent expressly permitted by
              the terms of this Agreement.
            </div>
            <br />
            <br />
            <div className='px-5'>
              <b className='medium'>9.8 Entire Agreement; Counterparts.&nbsp;</b>
              This Agreement, including the Multikrd Terms and Conditions of Use and all of the
              other documents incorporated herein by reference, constitutes the complete and
              exclusive agreement between the parties with respect to the subject matter hereof, and
              supersedes and replaces any and all prior or contemporaneous discussions,
              negotiations, understandings and agreements, written and oral, regarding such subject
              matter. In the event of any conflict or inconsistency between these Terms and
              Conditions and the Multikrd Platform Terms of Use, the terms that provide the greater
              rights and protections for Multikrd shall control. This Agreement may be executed in
              two or more counterparts, each of which will be deemed an original, but all of which
              together shall constitute one and the same instrument.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
