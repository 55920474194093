import React from 'react'
import ContactForm from '../components/contactForm'

export default function ContactOut () {
  return (
    <div>
      <div className='row p-5'>
        <div className='web-header pt-5 pb-3'>Contact</div>
      </div>
      <div className='row pt-0 pt-5-md pb-5'>
        <div className='col-3 d-none d-md-inline' />
        <div className='col-12 col-md-9 px-5 px-md-0'>
          <ContactForm emailRecipient='contact@multikrd.com' />
        </div>
      </div>
    </div>
  )
}
