import React from 'react'
import SlideImg from '../components/SlideImg'
import SlideHeader from '../components/SlideHeader'
import SlideCarousel from '../components/SlideCarousel'
import ImgHeader from '../assets/images/employees-header.png'
import ImgHeaderSm from '../assets/images/employees-header-sm.png'
import CarouselImg1 from '../assets/images/carrousel/employees-1.png'
import CarouselImg2 from '../assets/images/carrousel/employees-2.png'
import CarouselImg3 from '../assets/images/carrousel/employees-3.png'
import Img1 from '../assets/images/employees-1.png'
import Img2 from '../assets/images/employees-2.png'
import Img3 from '../assets/images/employees-3.svg'
import CardImg from '../assets/images/regular.png'
import Icon1 from '../assets/images/employees-icon-1.png'
import Icon2 from '../assets/images/employees-icon-2.png'
import Icon3 from '../assets/images/employees-icon-3.png'
import FigureHeader from '../assets/images/shapes/employees-header.png'

export default function Employees () {
  return (
    <div>
      <SlideHeader
        textPosition='right'
        img={ImgHeader}
        imgSm={ImgHeaderSm}
        title='Reap rewards, gain financial control, and save every day'
        text='Getting paid on your terms is just the beginning.'
        figure={FigureHeader}
        figureClass='figure-header-employees'
      />
      <div className='slide-employees-blue pb-5'>
        <div className='row'>
          <img className='col-12 col-sm-6 p-5' src={CardImg} alt='cardMk' />
          <div className='col-12 col-sm-6 d-flex align-items-center'>
            <div
              className='d-flex flex-column p-4 slide-employees-blue-header-container'
              style={{ color: 'white' }}
            >
              <div className='web-header text-start'>Introducing the MK Card</div>
              <div className='largue-text text-start pt-4'>
                It’s never been easier to improve your financial well-being.
              </div>
            </div>
          </div>
        </div>
        <div className='row pb-5 pb-lg-0'>
          {cardsBluePanel.map(e => {
            return (
              <div
                key={e.title}
                className='col-12 col-sm-6 col-md-4 p-2 p-lg-5'
                style={{ color: 'white' }}
              >
                <img
                  className='py-0 mb-3 mb-lg-0 mt-5 mt-sm-3 py-lg-5 slide-employees-blue-icon'
                  src={e.icon}
                  alt='icon'
                />
                <div className='web-subhead text-start px-5 pt-1 pb-4'>{e.title}</div>
                <div className='text-regular text-start px-5'>{e.text}</div>
              </div>
            )
          })}
        </div>
      </div>
      <SlideImg
        button={false}
        title={
          <div className='web-header d-flex flex-column '>
            <div className='web-subhead'>Quick access to funds:</div>It’s your money. You’ve earned
            it.
          </div>
        }
        text='MK connects directly to your employer’s payroll so you have timely access to a portion of the money you’ve already earned, whether it’s wages or tips. You can even set your own payday schedule. And unlike payday lenders, we don’t charge heavy interest rates or damage your credit.'
        img={Img1}
      />
      <SlideImg
        button={false}
        title='Personalized savings— anytime anywhere'
        text='We use smart recommendations and location-based alerts to send you relevant and timely promotions—both online and in-store. Save money every time you shop, whether you’re eating out, buying basics, or planning a trip.'
        img={Img2}
        textPosition='left'
      />
      <SlideImg
        button={false}
        title='Get real cashback'
        text='Earn up to 20% cashback on daily essentials—at stores where you already shop. National brands are part of our program. You can receive your cashback to your MK card or through a no-fee transfer to another account of your choice.'
        img={Img3}
      />
      <div className='py-5' />
      <SlideCarousel items={carouselItems} />
    </div>
  )
}

const carouselItems = [
  {
    title: '“Now I can make online payments and get cash at any ATM”',
    text: ' I never had a bank account until I started this job. My employer offered this solution and I immediately requested a card. Now I can make online payments and get cash at any ATM. ',
    autor: '- Jonathan T., Electrician',
    img: CarouselImg1,
    design: 3
  },
  {
    title: 'How I saved over $500',
    text: 'Last time I traveled with my husband and two kids, we booked through my app and we not only saved on hotels, but also purchased discounted theme park tickets, AND got cashback! ',
    autor: '- Jessica S., Speech Pathologist',
    header: 'USER STORY:',
    img: CarouselImg2,
    design: 2
  },
  {
    title: '“I love the Auto Wage Access feature”',
    text: 'I use my employee portal to look at my account balance, transfer money, make purchases, and request my earned wages. I love the Auto Wage Access feature. I know that every Monday I will be receiving what I want to spend that week.',
    autor: '- Caroline M., Receptionist',
    img: CarouselImg3,
    design: 1
  }
]

const cardsBluePanel = [
  {
    title: 'Easy access to pay and tip advances',
    text: 'Your MK Card is integrated with your earned wage advances and cashback—giving you quick, stress-free access to your money when you need it.',
    icon: Icon1
  },
  {
    title: 'No more back and forth with cash⁽¹⁾',
    text: 'You can set up your payroll direct deposit and manage your finances from the app. Make online and in-person payments and all kind of transfers using your MK Card and Account, and unlock additional benefits such as free wage access.',
    icon: Icon2
  },
  {
    title: 'We travel with you – always at hand',
    text: 'Access your MK card through Apple Pay or Google Pay, or simply keep a card in your wallet. Easy peasy.',
    icon: Icon3
  }
]
