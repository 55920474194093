import { TextField, makeStyles, Button, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'
import { postContact } from '../services/contact'

const useStyles = makeStyles(theme => ({
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    width: '15rem'
  }
}))

export default function ContactForm (props) {
  const classes = useStyles()

  const [state, setState] = useState({
    emailRecipient: props.emailRecipient,
    name: '',
    email: '',
    phone: '',
    companyContact: '',
    message: ''
  })
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    companyContact: '',
    message: ''
  })
  const [alertOpen, setOpenAlert] = useState(false)
  const [sendError, setSendError] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }

  const submit = () => {
    setSendError(false)
    let error = { name: '', email: '', phone: '', companyContact: '', message: '' }
    let errorFlag = false
    if (state.name.replace(/\s+/g, '').length === 0) {
      error = { ...error, name: 'The field cannot be empty' }
      errorFlag = true
    }
    if (state.email.replace(/\s+/g, '').length === 0) {
      error = { ...error, email: 'The field cannot be empty' }
      errorFlag = true
    }
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        state.email
      )
    ) {
      error = { ...error, email: 'Email invalid' }
      errorFlag = true
    }
    if (state.phone.replace(/\s+/g, '').length === 0) {
      error = { ...error, phone: 'The field cannot be empty' }
      errorFlag = true
    }
    if (state.companyContact.replace(/\s+/g, '').length === 0) {
      error = { ...error, companyContact: 'The field cannot be empty' }
      errorFlag = true
    }
    if (state.message.replace(/\s+/g, '').length === 0) {
      error = { ...error, message: 'The field cannot be empty' }
      errorFlag = true
    }
    setErrors(error)
    if (!errorFlag) {
      postContact(state).then(res => {
        if (res.success === false) {
          setSendError(true)
          setOpenAlert(true)
        } else {
          setSendError(false)
          setOpenAlert(true)
          setState({
            emailRecipient: props.emailRecipient,
            name: '',
            email: '',
            phone: '',
            companyContact: '',
            message: ''
          })
        }
      })
    }
  }

  return (
    <>
      <div className='pb-4'>
        <TextField
          label='Name'
          variant='outlined'
          fullWidth
          value={state.name}
          onChange={e => {
            setState({ ...state, name: e.target.value })
          }}
          error={errors.name !== ''}
          helperText={errors.name}
        />
      </div>
      <div className='pb-4'>
        <TextField
          label='Email'
          variant='outlined'
          fullWidth
          value={state.email}
          onChange={e => {
            setState({ ...state, email: e.target.value })
          }}
          error={errors.email !== ''}
          helperText={errors.email}
        />
      </div>
      <div className='pb-4'>
        <TextField
          label='Phone'
          variant='outlined'
          fullWidth
          value={state.phone}
          onChange={e => {
            setState({ ...state, phone: e.target.value })
          }}
          error={errors.phone !== ''}
          helperText={errors.phone}
        />
      </div>
      <div className='pb-4'>
        <TextField
          label='Employee/company contact'
          variant='outlined'
          fullWidth
          value={state.employ}
          onChange={e => {
            setState({ ...state, companyContact: e.target.value })
          }}
          error={errors.companyContact !== ''}
          helperText={errors.companyContact}
        />
      </div>
      <div className='pb-4'>
        <TextField
          label='Leave us a message'
          variant='outlined'
          fullWidth
          multiline
          minRows={5}
          value={state.message}
          onChange={e => {
            setState({ ...state, message: e.target.value })
          }}
          error={errors.message !== ''}
          helperText={errors.message}
        />
      </div>
      <div className='pb-4 d-flex justify-content-start'>
        <Button variant='outlined' className={classes.buttonSecondaryBlack} onClick={submit}>
          Get in touch
        </Button>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={sendError ? 'error' : 'success'}>
          {sendError ? 'Sending error' : 'The form has been submitted successfully'}
        </Alert>
      </Snackbar>
    </>
  )
}
