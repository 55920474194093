import { Navigate, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import MetaTags from './components/MetaTags'
import Navbar from './components/Navbar'
import Footer from './components/footer/Footer'
import Benefits from './pages/Benefits'
import HowItWorks from './pages/HowItWoks'
import Employees from './pages/Employees'
import Customers from './pages/Customers'
import Signup from './pages/Signup'
import About from './pages/About'
import FAQs from './pages/FAQs'
import Contact from './pages/Contact'
import ContactOut from './pages/ContactOut'
import Media from './pages/Media'
import Blog from './pages/Blog'
import ScrollToTop from './helpers/ScrollTop'
import TermsAndConditions from './pages/T&C.js'
import { checkB2CSubdomain } from './helpers/utils'
import Paths from './helpers/paths'
import { B2C_FEATURE_ACTIVE } from './helpers/constants'

if (!B2C_FEATURE_ACTIVE) {
  const currentDomain = window.location.hostname.split('.')[0]
  const BASE_DOMAIN = 'multikrd'
  const DEV_DOMAINS = ['localhost', 'test', 'landing']
  if (currentDomain !== BASE_DOMAIN && !DEV_DOMAINS.includes(currentDomain)) {
    const baseURL = window.location.origin.replace(`${currentDomain}.`, '')
    window.location.replace(baseURL)
  }
}

function App () {
  const isB2C = B2C_FEATURE_ACTIVE ? checkB2CSubdomain() : false
  
  return (
    <div className='App'>
      <MetaTags />
      <ScrollToTop>
        <Navbar fixed />
        <Routes>
          <Route path={Paths.ROOT} element={isB2C ? <Customers /> : <Home />} />
          <Route path={Paths.HOME} element={<Home />} />
          <Route path={Paths.BENEFITS} element={<Benefits />} />
          <Route path={Paths.HOW_IT_WORKS} element={<HowItWorks />} />
          <Route path={Paths.EMPLOYEES} element={<Employees />} />
          {B2C_FEATURE_ACTIVE && (
            <>
              <Route path={Paths.CUSTOMERS} element={<Customers />} />
              <Route path={Paths.SIGNUP} element={<Signup />} />
            </>
          )}
          <Route path={Paths.ABOUT_US} element={<About />} />
          <Route path={Paths.FAQS} element={<FAQs />} />
          <Route path={Paths.CONTACT} element={<Contact />} />
          <Route path={Paths.CONTACT_US} element={<ContactOut />} />
          <Route path={Paths.MEDIA} element={<Media />} />
          <Route path={Paths.BLOG} element={<Blog />} />
          <Route path={Paths.BLOG + '/:id'} element={<Blog />} />
          <Route path={Paths.LEGAL_AGREEMENTS} element={<TermsAndConditions />} />
          <Route path="*" element={<Navigate to={Paths.ROOT} />} />
        </Routes>
        <Footer />
      </ScrollToTop>
    </div>
  )
}

export default App
