const Paths = {
  ROOT: '/',
  HOME: '/home',
  BENEFITS: '/benefits',
  HOW_IT_WORKS: '/how-it-works',
  EMPLOYEES: '/employees',
  CUSTOMERS: '/customers',
  SIGNUP: '/signup',
  ABOUT_US: '/about-us',
  FAQS: '/FAQs',
  CONTACT: '/contact',
  CONTACT_US: '/contact-us',
  MEDIA: '/media',
  BLOG: '/blog',
  LEGAL_AGREEMENTS: '/masterservicesagreement-termsandconditions'
}

export default Paths
