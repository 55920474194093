import { makeStyles, Button } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import React, { Fragment, useState } from 'react'
import ImgDocument from '../assets/images/documents/documents-1.png'
import { compliance, flyer } from '../assets/images/documents'
import IconDocument from '../assets/icons/document.png'
import DialogVideo from '../components/DIalogVideo'

const useStyles = makeStyles(theme => ({
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10
  }
}))

export default function Media () {
  const [open, setOpen] = useState(false)
  const [selectVideo, setSelectVideo] = useState(0)

  return (
    <div className='px-2 px-sm-5 pt-5'>
      <DialogVideo open={open} setOpen={setOpen} element={videos[selectVideo]} />
      <div className='row p-5'>
        <div className='web-header'>Videos</div>
      </div>
      <Carousel indicators={false} className='w-100 carrousel-video'>
        {videos.map((item, i) => (
          <Item
            key={`carrousel-${i}`}
            item={item}
            open={open}
            setVideo={setSelectVideo}
            setOpen={setOpen}
            index={i}
          />
        ))}
      </Carousel>
      <div className='row'>
        {videos.map((e, index) => {
          return (
            <div
              key='video-{index}'
              className='col-12 col-sm-6 col-md-4 p-4'
              style={{ textAlign: 'left' }}
            >
              <div>
                <Button
                  onClick={() => {
                    setOpen(true)
                    setSelectVideo(index)
                  }}
                >
                  <img src={e.img} alt='video-img' className='media-header-img' />
                </Button>
              </div>
              <div className='medium pt-4 text-start'>{e.title}</div>
              <div className='text-regular pb-4'>{e.date}</div>
              <div className='text-regular pb-5'>
                {e.description}
                <Button
                  onClick={() => {
                    setOpen(true)
                    setSelectVideo(index)
                  }}
                  color='primary'
                  className='more-button'
                >
                  + more
                </Button>
              </div>
            </div>
          )
        })}
      </div>
      <div className='row p-5 mt-5'>
        <div className='web-header'>Documents</div>
      </div>
      <div className='row justify-content-center'>
        {documents.map((e, index) => {
          return (
            <Fragment key={`document-${index}`}>
              <div className='col p-5 d-table d-md-flex w-50'>
                <Button
                  onClick={() => {
                    const createA = document.createElement('a')
                    createA.setAttribute('href', e.link)
                    createA.setAttribute('target', '_blank')
                    createA.click()
                  }}
                  style={{ position: 'relative' }}
                >
                  <img
                    className='w-100'
                    src={e.img !== undefined ? e.img : ImgDocument}
                    alt='document'
                    style={{ right: '0px' }}
                  />
                  <img src={IconDocument} alt='icon-document' className='icon-document' />
                </Button>
                <div className='d-flex flex-column ps-4 ms-4 border-top'>
                  <div className='medium pt-2 text-start'>{e.title}</div>
                  <div className='text-regular text-start'>{e.date}</div>
                </div>
              </div>
              {(index + 1) % 2 === 0 && <div class='w-100' />}
              {index + 1 === documents.length && <div class='w-50' />}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

function Item (props) {
  const classes = useStyles()

  return (
    <div className='row pb-5 mb-5'>
      <div
        className='col-12 col-md-4 p-3 p-lg-5 order-2 order-md-1'
        style={{ background: '#FDBA63', textAlign: 'left' }}
      >
        <div className='medium'>{props.item.title}</div>
        <div className='text-regular pb-5'>{props.item.date}</div>
        <div className='text-regular pb-5'>{props.item.description}</div>
        <Button
          variant='outlined'
          className={`button-media ${classes.buttonSecondaryBlack}`}
          onClick={() => {
            props.setVideo(props.index)
            props.setOpen(true)
          }}
        >
          Watch Video
        </Button>
      </div>
      <div className='col-12 col-md-8 order-1 order-md-2'>
        <img src={props.item.img} alt='img-video' width='100%' />
      </div>
    </div>
  )
}

const videos = [
  {
    title: 'Introducing the Multikrd Financial Wellness membership',
    description:
      'Multikrd is a zero-cost solution designed to boost your employees’ financial health and motivation. This is the only platform that integrates wage access, builds cardowner credit, offers cashback savings and members- only discounts at over 1.5 million affiliated merchants. Be a business where good things happen all the time! Multikrd enriches and rewards employees who join your team, contribute fully, and stick around: Remember that motivated employees are happier, more engaged, and more productive.',
    url: 'https://youtu.be/MIcIFSMMnus',
    img: 'https://img.youtube.com/vi/MIcIFSMMnus/sddefault.jpg'
  },
  {
    title: 'Activate your Multikrd membership!',
    description:
      'Welcome to your Multikrd Wellness Membership! Discover the one of a kind program that rewards you for being a valued employee and gives you access to special promotions not available to the open public. Make your life easier through Earned Wage Access, Real Cashback to your account, thousands of promotions at over 700,000 retailers and 6,000 online stores, Travel, Adventures and much more!',
    url: 'https://youtu.be/MIcIFSMMnus',
    img: 'https://img.youtube.com/vi/MIcIFSMMnus/sddefault.jpg'
  },
  {
    title: 'Unlock your Earned Wages Access with the MK membership',
    description:
      "Multikrd's Easy Banking service gives you simple and fast access to a bank account and a secured credit card.In this video you will learn about the simple steps it takes to get yours!",
    url: 'https://youtu.be/wTtYLhOxw18',
    img: 'https://img.youtube.com/vi/wTtYLhOxw18/sddefault.jpg'
  },
  {
    title: 'Be a smar shopper and experience more',
    description:
      'Your MK Financial Wellness Membership is the best way to stretch your budget, thanks to the exclusive tools it offers. Take advantage of the greatest cashback program, the biggest discount network of affiliated merchants, and experience the adventures you have dreamed of, all at the reach of your hand. Shop smart, save and experience more!',
    url: 'https://youtu.be/1cVcQYkL5G8',
    img: 'https://img.youtube.com/vi/1cVcQYkL5G8/sddefault.jpg'
  }
]

const documents = [
  // {
  //   title: 'Multikrd Presentation',
  //   link: 'https://s3-landing-page-content.s3.amazonaws.com/Multikrd+Sales+Presentation.pdf',
  //   img: presentation
  // },
  {
    title: 'Multikrd Flyer',
    link: 'https://s3-landing-page-content.s3.amazonaws.com/Multikrd+One-Pager.pdf',
    img: flyer
  },
  {
    title: 'Multikrd Compliance Guide',
    link: 'https://s3-landing-page-content.s3.amazonaws.com/MKComplianceGuide.pdf',
    img: compliance
  }
]
