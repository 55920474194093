import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Open from '../assets/icons/add-circle.png'
import Close from '../assets/icons/minus-cirlce.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}))

export default function Acordion (props) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      {props.content.map((e, index) => {
        return (
          <Accordion
            key={index}
            className={expanded === 'panel' + index ? 'accordion-item-active' : 'accordion-item'}
            expanded={expanded === 'panel' + index}
            onChange={handleChange('panel' + index)}
          >
            <AccordionSummary
              expandIcon={
                expanded === 'panel' + index
                  ? (
                    <img src={Close} alt='more' />
                    )
                  : (
                    <img src={Open} alt='more' />
                    )
              }
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <div className='accordion-title text-start'>{e.title}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='accordion-text'>{e.text}</div>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}
